import React, {useEffect, useState, useRef} from 'react';
import {Layout, Row, Card, Col, Form, Input, Button, notification, message, Space, Table, Tag} from "antd";
import axios from "axios";
import {Link, Navigate} from 'react-router-dom';
import {userdataAtom} from "../../../state_atoms";
import {useRecoilState} from "recoil";
import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';

const { Content } = Layout;
function antdTableSorter(a, b, key) {
    if(typeof a[key] === 'number' && typeof b[key] === 'number') {
        return a[key] - b[key]
    }

    if(typeof a[key] === 'string' && typeof b[key] === 'string') {
        a = a[key].toLowerCase();
        b = b[key].toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
    }
}

interface DataType {
    id: string;
    name: string;
    breach_date: string;
    status: string;
}

type DataIndex = DataType;

const AdminHome: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectBack, setRedirectBack] = useState(false);
    const [userData, setUserData] = useRecoilState(userdataAtom);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [leakDataOpen, setLeakDataOpen] = useState('');
    const [leakDataDone, setLeakDataDone] = useState('');
    const searchInput = useRef(null);

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect( () => {
        if(userData === false) {
            setRedirectBack(true);
            return;
        }
        axios.get('https://' + window.location.hostname + '/api/leak', {withCredentials: true})
            .then(response => {
                setLeakDataOpen(response.data.data.filter(obj => { return obj.status === "open"}));
                setLeakDataDone(response.data.data.filter(obj => { return obj.status !== "open"}));
            }).catch(function(error) {
            if(error.response) {
                if(error.response.data.hasOwnProperty("error_message")) {
                    Object.values(error.response.data.error_message).map(function (item) {
                        notification.error({
                            message: 'Failed to retrieve data.',
                            description: item,
                            duration: 5
                        });
                    });
                } else {
                    notification.error({
                        message: 'Failed to retrieve data.',
                        description: error.response.data.message,
                        duration: 5
                    });
                    // message.error("Upload failed. " + error.response.data.message, 30);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [userData]);
    if(redirectBack) {
        return <Navigate replace to="/login" />;
    }


    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };


    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys)[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });


    const columns = [
        {
            title: 'Name',
            dataIndex: 'database_name',
            key: 'database_name',
            ...getColumnSearchProps('database_name'),
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Domain',
            dataIndex: 'domain',
            key: 'domain',
            ...getColumnSearchProps('domain'),
            // sorter: (a, b) => a.domain - b.domain,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => antdTableSorter(a, b, 'status'),
            render: (_, { status }) => (
                <>
                    {status === "open" && (
                        <Tag color="gold" key={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </Tag>
                    )}
                    {status === "accepted" && (
                        <Tag color="green" key={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </Tag>
                    )}
                    {status === "declined" && (
                        <Tag color="red" key={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </Tag>
                    )}
                    {status === "imported" && (
                        <Tag color="green" key={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </Tag>
                    )}
                </>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    {record.status === "open" &&
                        <Link to={"/admin/submission/" + record.id}>
                            <Button>
                                Start Review
                            </Button>
                        </Link>
                    }
                    {record.status !== "open" &&
                        <Link to={"/admin/submission/" + record.id}>
                            <Button>
                                View Details
                            </Button>
                        </Link>
                    }
                </>
            ),
        },
    ];




    return (
        <Content
            style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
            }}
        >
            <Row gutter={[16, 16]}>
                <Col span={18} offset={3}>
                    <Card title={"Admin Homepage"}>
                        Welcome to the HashMob leak submission management interface.<br/>
                    </Card>
                </Col>
                <Col span={18} offset={3}>
                    <Table columns={columns} dataSource={leakDataOpen} rowKey={'id'} />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={18} offset={3}>
                    <Table columns={columns} dataSource={leakDataDone} rowKey={'id'} />
                </Col>
            </Row>
        </Content>
    );
};

export default AdminHome;