import React, {useEffect, useState} from 'react';
import SubmitLeak from './views/SubmitLeak';
import Login from './views/Login';
import AdminHome from './views/Admin/Home';
import AdminSubmissionView from './views/Admin/SubmissionView';
import {ConfigProvider, theme, Layout, Spin} from "antd";
import axios from "axios";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";
import {userdataAtom} from "./state_atoms";
import {useRecoilState} from "recoil";

const { Footer } = Layout;
const { darkAlgorithm } = theme;


const router = createBrowserRouter([
    {
        path: "/",
        element: <SubmitLeak/>,
    },
    {
        path: "/login",
        element: <Login/>,
    },
    {
        path: "/admin",
        element: <AdminHome/>,
    },
    {
        path: "/admin/submission/:leakId",
        element: <AdminSubmissionView/>,
    },
    {
        path: "*",
        element: <Navigate to={'/'}/>,
    }
]);

const App: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useRecoilState(userdataAtom);
    const [userDataLoading, setUserDataLoading] = useState(true);

    useEffect( () => {
        const update_user_session = async () => {
            await axios.get('https://' + window.location.hostname + '/api/user', {withCredentials: true})
                .then(response => {
                    if (response.data.hasOwnProperty('username')) {
                        if(userData !== response.data) {
                            setUserData(response.data);
                            setUserDataLoading(false);
                            setLoading(false);
                        }
                    } else {
                        setUserData(false);
                        setLoading(false);
                        setUserDataLoading(false);
                    }
                }).catch(error => {
                    console.log(error.data);
                    setUserDataLoading(false);
                    setLoading(false);
                })
        }
        update_user_session();
    }, []);

    return (
        <ConfigProvider
            theme={{
                algorithm: darkAlgorithm,
            }}
        >
            <Spin spinning={loading} size={'large'}>
                <Layout style={{ minHeight: '100vh' }}>
                    <React.StrictMode>
                        {(!userDataLoading) && <RouterProvider router={router} />}
                    </React.StrictMode>
                    <Footer style={{ textAlign: 'center' }}>HashMob utility</Footer>
                </Layout>
            </Spin>
        </ConfigProvider>
    );
};

export default App;