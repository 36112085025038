import React, {useEffect, useState, useRef} from 'react';
import {
    Layout,
    Row,
    Card,
    Col,
    Form,
    Input,
    Button,
    notification,
    message,
    Space,
    Table,
    Tag,
    Descriptions,
    Select, Upload, Spin
} from "antd";
import axios from "axios";
import {Link, Navigate, useParams} from 'react-router-dom';
import {userdataAtom} from "../../../state_atoms";
import {NumericFormat} from 'react-number-format';
import {useRecoilState} from "recoil";
import {
    SearchOutlined,
    DownloadOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    MinusCircleOutlined, UploadOutlined, PlusOutlined
} from '@ant-design/icons';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import type { UploadProps } from 'antd/es/upload/interface';

const { Content } = Layout;
const { TextArea } = Input;
function antdTableSorter(a, b, key) {
    if(typeof a[key] === 'number' && typeof b[key] === 'number') {
        return a[key] - b[key]
    }

    if(typeof a[key] === 'string' && typeof b[key] === 'string') {
        a = a[key].toLowerCase();
        b = b[key].toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
    }
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
    },
};

function isNumeric(value) {
    return /^-?\d+$/.test(value);
}
const AdminSubmissionView: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [redirectBack, setRedirectBack] = useState(false);
    const [userData, setUserData] = useRecoilState(userdataAtom);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [previewData, setPreviewData] = useState('');
    const [leakData, setLeakData] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileList2, setFileList2] = useState([]);
    const [fileList3, setFileList3] = useState([]);
    const [fileList4, setFileList4] = useState([]);
    const [fileList5, setFileList5] = useState([]);
    const [uploading, setUploading] = useState([]);
    const [algorithms, setAlgorithms] = useState([undefined, undefined, undefined, undefined, undefined]);
    const searchInput = useRef(null);
    let { leakId } = useParams();



    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect( () => {
        if(userData === false) {
            setRedirectBack(true);
            return;
        }
        if(!isNumeric(leakId)) setRedirectBack(true);
        axios.get('https://' + window.location.hostname + '/api/leak/' + leakId, {withCredentials: true})
            .then(response => {
                setLeakData(response.data.data);
            }).catch(function(error) {
            if(error.response) {
                if(error.response.data.hasOwnProperty("error_message")) {
                    Object.values(error.response.data.error_message).map(function (item) {
                        notification.error({
                            message: 'Failed to retrieve data.',
                            description: item,
                            duration: 5
                        });
                    });
                } else {
                    notification.error({
                        message: 'Failed to retrieve data.',
                        description: error.response.data.message,
                        duration: 5
                    });
                    // message.error("Upload failed. " + error.response.data.message, 30);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [userData]);
    if(redirectBack) {
        return <Navigate replace to="/admin" />;
    }


    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const refreshLeakData = () => {
        axios.get('https://' + window.location.hostname + '/api/leak/' + leakId, {withCredentials: true})
            .then(response => {
                setLeakData(response.data.data);
            }).catch(function(error) {
            if(error.response) {
                if(error.response.data.hasOwnProperty("error_message")) {
                    Object.values(error.response.data.error_message).map(function (item) {
                        notification.error({
                            message: 'Failed to retrieve data.',
                            description: item,
                            duration: 5
                        });
                    });
                } else {
                    notification.error({
                        message: 'Failed to retrieve data.',
                        description: error.response.data.message,
                        duration: 5
                    });
                    // message.error("Upload failed. " + error.response.data.message, 30);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys)[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });

    const acceptSubmission = () => {
        axios.get('https://' + window.location.hostname + '/api/leak/' + leakId + '/accept', {withCredentials: true})
            .finally(() => {
                refreshLeakData();
            });

    }
    const declineSubmission = () => {
        axios.get('https://' + window.location.hostname + '/api/leak/' + leakId + '/decline', {withCredentials: true})
            .finally(() => {
                refreshLeakData();
            });
    }
    const openSubmission = () => {
        axios.get('https://' + window.location.hostname + '/api/leak/' + leakId + '/open', {withCredentials: true})
            .finally(() => {
                refreshLeakData();
            });
    }

    const doSubmit = () => {
        let error = false;
        if(algorithms[0] === undefined) {
            return;
        }

        if(fileList.length === 0) {
            error = true;
            message.error("Algorithm 1 is missing a file.");
        }

        if(algorithms[1] !== undefined && fileList2.length === 0) {
            error = true;
            message.error("Algorithm 2 is missing a file.");
        }

        if(algorithms[2] !== undefined && fileList3.length === 0) {
            error = true;
            message.error("Algorithm 3 is missing a file.");
        }

        if(algorithms[3] !== undefined && fileList4.length === 0) {
            error = true;
            message.error("Algorithm 4 is missing a file.");
        }

        if(algorithms[3] !== undefined && fileList5.length === 0) {
            error = true;
            message.error("Algorithm 5 is missing a file.");
        }
        if(error) return;

        const formData = new FormData();
        algorithms.forEach((algorithm) => {
            if(algorithm !== undefined && algorithm !== null) {
                formData.append('algorithms[]', algorithm);
            }
        });
        fileList.forEach((file) => {
            console.log(file)
            formData.append('algorithm1[]', file);
        });
        fileList2.forEach((file) => {
            formData.append('algorithm2[]', file);
        });
        fileList3.forEach((file) => {
            formData.append('algorithm3[]', file);
        });
        fileList4.forEach((file) => {
            formData.append('algorithm4[]', file);
        });
        fileList5.forEach((file) => {
            formData.append('algorithm5[]', file);
        });
        setUploading(true);
        // You can use any AJAX library you like
        fetch('https://leaksubmit.hashmob.net/api/leak/' + leakData['id'] + '/add_algorithm', {
            method: 'POST',
            body: formData,
        })
            .then((res) => {
                res.json();
                setAlgorithms([undefined, undefined, undefined, undefined, undefined]);
                setFileList([]);
                setFileList2([]);
                setFileList3([]);
                setFileList4([]);
                setFileList5([]);
                // form.resetFields();
                message.success('Uploaded successfully.');
            })
            .catch(function(error) {
                if (error.response) {
                    if (error.response.data.hasOwnProperty("error_message")) {
                        Object.values(error.response.data.error_message).map(function (item) {
                            message.error("Upload failed. " + item, 30);
                        });
                    } else {
                        message.error("Upload failed. " + error.response.data.message, 30);
                    }
                }
            }).finally(() => {
                refreshLeakData();
                setUploading(false);
            });
    }

    const loadAlgorithmPreview = (algorithmId) => {
        setLoading(true);
        axios.get('https://' + window.location.hostname + '/api/leak/' + leakId + '/algorithm/' + algorithmId + '/preview', {withCredentials: true})
            .then(response => {
                setPreviewData(response.data.data);
            }).catch(function(error) {
            if(error.response) {
                if(error.response.data.hasOwnProperty("error_message")) {
                    Object.values(error.response.data.error_message).map(function (item) {
                        notification.error({
                            message: 'Failed to retrieve preview data.',
                            description: item,
                            duration: 5
                        });
                    });
                } else {
                    notification.error({
                        message: 'Failed to retrieve preview data.',
                        description: error.response.data.message,
                        duration: 5
                    });
                    // message.error("Upload failed. " + error.response.data.message, 30);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const deleteAlgorithm = (algorithmId) => {
        setLoading(true);
        axios.get('https://' + window.location.hostname + '/api/leak/' + leakId + '/algorithm/' + algorithmId + '/delete', {withCredentials: true})
            .then().catch(function(error) {
                if(error.response) {
                    if(error.response.data.hasOwnProperty("error_message")) {
                        Object.values(error.response.data.error_message).map(function (item) {
                            notification.error({
                                message: 'Failed to delete Algorithm.',
                                description: item,
                                duration: 5
                            });
                        });
                    } else {
                        notification.error({
                            message: 'Failed to delete Algorithm.',
                            description: error.response.data.message,
                            duration: 5
                        });
                        // message.error("Upload failed. " + error.response.data.message, 30);
                    }
                }
        }).finally(() => {
            setLoading(false);
        });
    }

    const columns = [
        {
            title: 'Algorithm',
            dataIndex: 'algorithm',
            key: 'algorithm',
            ...getColumnSearchProps('algorithm'),
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Hashcat Algorithm ID',
            dataIndex: 'hashcat_algorithm',
            key: 'hashcat_algorithm',
            ...getColumnSearchProps('hashcat_algorithm'),
            // sorter: (a, b) => a.domain - b.domain,
        },
        {
            title: 'Line Count',
            dataIndex: 'file_lines',
            key: 'file_lines',
            ...getColumnSearchProps('file_lines'),
            render: (text, record) => (
                <center>
                    <NumericFormat
                        value={record.algorithm_files[Object.keys(record.algorithm_files)[0]].file_lines}
                        thousandsGroupStyle="lakh"
                        thousandSeparator=" "
                        displayType="text"
                        renderText={(value) => <b>{value}</b>}
                    />
                </center>
            ),
            // sorter: (a, b) => a.domain - b.domain,
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <center>
                    <Button style={{marginRight: "10px"}} onClick={() => {loadAlgorithmPreview(record.algorithm_files[Object.keys(record.algorithm_files)[0]].id)}}>
                        <EyeOutlined />
                    </Button>
                    <a href={"/api/leak/" + leakId + "/algorithm/" + record.algorithm_files[Object.keys(record.algorithm_files)[0]].id} target="_blank" style={{marginRight: "10px"}}>
                        <Button>
                            <DownloadOutlined />
                        </Button>
                    </a>
                    <Button style={{marginRight: "10px"}} disabled>
                        <EditOutlined />
                    </Button>
                    <Button style={{marginRight: "10px"}} onClick={() => {deleteAlgorithm(record.id)}}>
                        <DeleteOutlined />
                    </Button>
                </center>
            ),
        },
    ];

    const clearList = (index) => {
        switch(index) {
            case 0:
                setFileList([]);
                break;
            case 1:
                setFileList2([]);
                break;
            case 2:
                setFileList3([]);
                break;
            case 3:
                setFileList4([]);
                break;
            case 4:
                setFileList5([]);
                break;
        }
    }


    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const props2: UploadProps = {
        onRemove: (file) => {
            const index = fileList2.indexOf(file);
            const newFileList = fileList2.slice();
            newFileList.splice(index, 1);
            setFileList2(newFileList);
        },
        beforeUpload: (file) => {
            setFileList2([...fileList2, file]);

            return false;
        },
        fileList2,
    };

    const props3: UploadProps = {
        onRemove: (file) => {
            const index = fileList3.indexOf(file);
            const newFileList = fileList3.slice();
            newFileList.splice(index, 1);
            setFileList3(newFileList);
        },
        beforeUpload: (file) => {
            setFileList3([...fileList3, file]);

            return false;
        },
        fileList3,
    };

    const props4: UploadProps = {
        onRemove: (file) => {
            const index = fileList4.indexOf(file);
            const newFileList = fileList4.slice();
            newFileList.splice(index, 1);
            setFileList4(newFileList);
        },
        beforeUpload: (file) => {
            setFileList4([...fileList4, file]);

            return false;
        },
        fileList4,
    };

    const props5: UploadProps = {
        onRemove: (file) => {
            const index = fileList5.indexOf(file);
            const newFileList = fileList5.slice();
            newFileList.splice(index, 1);
            setFileList5(newFileList);
        },
        beforeUpload: (file) => {
            setFileList5([...fileList5, file]);

            return false;
        },
        fileList5,
    };

    const clearAlgorithm = (index) => {
        let copy = algorithms;
        copy[index] = undefined;
        setAlgorithms(copy);
    }

    const updateAlgorithm = (index, new_algorithm) => {
        let copy = algorithms;
        copy[index] = new_algorithm;
        setAlgorithms(copy);
    }

    return (
        <Content
            style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
            }}
        >
            <Row gutter={[16, 16]}>
                <Col span={18} offset={3}>
                    <Card title={"Submission Details"}>
                        <Descriptions>
                            <Descriptions.Item label="Breach Name">{leakData['database_name']}</Descriptions.Item>
                            <Descriptions.Item label="Domain">{leakData['domain']}</Descriptions.Item>
                            <Descriptions.Item label="Breach Date">{leakData['breach_date']}</Descriptions.Item>
                            <Descriptions.Item label="Breach Date Source">{leakData['breach_date_source']}</Descriptions.Item>
                            <Descriptions.Item label="Line Count">
                                {leakData &&
                                    <NumericFormat
                                        value={leakData['breach_files'][0]['file_lines']}
                                        thousandsGroupStyle="lakh"
                                        thousandSeparator=" "
                                        displayType="text"
                                    />
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Submit Date">{leakData['created_at']}</Descriptions.Item>
                            <Descriptions.Item label="Status">{leakData['status']}</Descriptions.Item>
                            {(leakData) &&
                                <Descriptions.Item label="Industries">
                                    {(leakData['industries'].map(function(a) { return a.industry })).join(", ")}
                                </Descriptions.Item>
                            }
                            <Descriptions.Item label="Note">{leakData['note']}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                <Col span={18} offset={3}>
                    <Table columns={columns} dataSource={leakData.algorithms} rowKey={'id'} />
                </Col>
                <Col span={18} offset={3}>
                    <Spin spinning={uploading.length !== 0} size={'large'}>
                        <Card title={"Modify Algorithms"}>
                        <Form>
                            <Form.Item>
                            <Form.List name={"placeholder"}>
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                required={false}
                                                key={field.key}
                                                style={{marginBottom: "10px"}}
                                            >
                                                {fields.length >= 1 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => {
                                                            clearList(index);
                                                            clearAlgorithm(index);
                                                            remove(field.name);
                                                        }}
                                                        style={{marginRight: "10px"}}
                                                    />
                                                ) : null}
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the algorithm & Upload the file.",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: "40%", marginRight: "15px"}}
                                                        placeholder="Select a hashcat algorithm"
                                                        optionFilterProp="children"
                                                        onChange={(value) => updateAlgorithm(index, value)}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Select.Option value="-2">Unknown</Select.Option>
                                                        <Select.Option value="-1">Other</Select.Option>
                                                        <Select.Option value="0">0 - MD5</Select.Option>
                                                        <Select.Option value="10">10 - md5($pass.$salt)</Select.Option>
                                                        <Select.Option value="11">11 - Joomla &lt; 2.5.18</Select.Option>
                                                        <Select.Option value="12">12 - PostgreSQL</Select.Option>
                                                        <Select.Option value="20">20 - md5($salt.$pass)</Select.Option>
                                                        <Select.Option value="21">21 - osCommerce, xt:Commerce</Select.Option>
                                                        <Select.Option value="22">22 - Juniper NetScreen/SSG (ScreenOS)</Select.Option>
                                                        <Select.Option value="23">23 - Skype</Select.Option>
                                                        <Select.Option value="24">24 - SolarWinds Serv-U</Select.Option>
                                                        <Select.Option value="30">30 - md5(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="40">40 - md5($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="50">50 - HMAC-MD5 (key = $pass)</Select.Option>
                                                        <Select.Option value="60">60 - HMAC-MD5 (key = $salt)</Select.Option>
                                                        <Select.Option value="70">70 - md5(utf16le($pass))</Select.Option>
                                                        <Select.Option value="100">100 - SHA1</Select.Option>
                                                        <Select.Option value="101">101 - nsldap, SHA-1(Base64), Netscape LDAP SHA</Select.Option>
                                                        <Select.Option value="110">110 - sha1($pass.$salt)</Select.Option>
                                                        <Select.Option value="111">111 - nsldaps, SSHA-1(Base64), Netscape LDAP SSHA</Select.Option>
                                                        <Select.Option value="112">112 - Oracle S: Type (Oracle 11+)</Select.Option>
                                                        <Select.Option value="120">120 - sha1($salt.$pass)</Select.Option>
                                                        <Select.Option value="121">121 - SMF (Simple Machines Forum) > v1.1</Select.Option>
                                                        <Select.Option value="122">122 - macOS v10.4, macOS v10.5, macOS v10.6</Select.Option>
                                                        <Select.Option value="124">124 - Django (SHA-1)</Select.Option>
                                                        <Select.Option value="125">125 - ArubaOS</Select.Option>
                                                        <Select.Option value="130">130 - sha1(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="131">131 - MSSQL (2000)</Select.Option>
                                                        <Select.Option value="132">132 - MSSQL (2005)</Select.Option>
                                                        <Select.Option value="133">133 - PeopleSoft</Select.Option>
                                                        <Select.Option value="140">140 - sha1($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="141">141 - Episerver 6.x &lt; .NET 4</Select.Option>
                                                        <Select.Option value="150">150 - HMAC-SHA1 (key = $pass)</Select.Option>
                                                        <Select.Option value="160">160 - HMAC-SHA1 (key = $salt)</Select.Option>
                                                        <Select.Option value="170">170 - sha1(utf16le($pass))</Select.Option>
                                                        <Select.Option value="200">200 - MySQL323</Select.Option>
                                                        <Select.Option value="300">300 - MySQL4.1/MySQL5</Select.Option>
                                                        <Select.Option value="400">400 - phpass</Select.Option>
                                                        <Select.Option value="500">500 - md5crypt, MD5 (Unix), Cisco-IOS $1$ (MD5)</Select.Option>
                                                        <Select.Option value="501">501 - Juniper IVE</Select.Option>
                                                        <Select.Option value="600">600 - BLAKE2b-512</Select.Option>
                                                        <Select.Option value="610">610 - BLAKE2b-512($pass.$salt)</Select.Option>
                                                        <Select.Option value="620">620 - BLAKE2b-512($salt.$pass)</Select.Option>
                                                        <Select.Option value="900">900 - MD4</Select.Option>
                                                        <Select.Option value="1000">1000 - NTLM</Select.Option>
                                                        <Select.Option value="1001">1001 - Troy Hunt NTLM</Select.Option>
                                                        <Select.Option value="1100">1100 - Domain Cached Credentials (DCC), MS Cache</Select.Option>
                                                        <Select.Option value="1300">1300 - SHA2-224</Select.Option>
                                                        <Select.Option value="1400">1400 - SHA2-256</Select.Option>
                                                        <Select.Option value="1410">1410 - sha256($pass.$salt)</Select.Option>
                                                        <Select.Option value="1411">1411 - SSHA-256(Base64), LDAP SSHA256</Select.Option>
                                                        <Select.Option value="1420">1420 - sha256($salt.$pass)</Select.Option>
                                                        <Select.Option value="1421">1421 - hMailServer</Select.Option>
                                                        <Select.Option value="1430">1430 - sha256(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="1440">1440 - sha256($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="1441">1441 - Episerver 6.x >= .NET 4</Select.Option>
                                                        <Select.Option value="1450">1450 - HMAC-SHA256 (key = $pass)</Select.Option>
                                                        <Select.Option value="1460">1460 - HMAC-SHA256 (key = $salt)</Select.Option>
                                                        <Select.Option value="1470">1470 - sha256(utf16le($pass))</Select.Option>
                                                        <Select.Option value="1500">1500 - descrypt, DES (Unix), Traditional DES</Select.Option>
                                                        <Select.Option value="1600">1600 - Apache $apr1$ MD5, md5apr1, MD5 (APR)</Select.Option>
                                                        <Select.Option value="1700">1700 - SHA2-512</Select.Option>
                                                        <Select.Option value="1710">1710 - sha512($pass.$salt)</Select.Option>
                                                        <Select.Option value="1711">1711 - SSHA-512(Base64), LDAP SSHA512</Select.Option>
                                                        <Select.Option value="1720">1720 - sha512($salt.$pass)</Select.Option>
                                                        <Select.Option value="1722">1722 - macOS v10.7</Select.Option>
                                                        <Select.Option value="1730">1730 - sha512(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="1731">1731 - MSSQL (2012, 2014)</Select.Option>
                                                        <Select.Option value="1740">1740 - sha512($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="1750">1750 - HMAC-SHA512 (key = $pass)</Select.Option>
                                                        <Select.Option value="1760">1760 - HMAC-SHA512 (key = $salt)</Select.Option>
                                                        <Select.Option value="1770">1770 - sha512(utf16le($pass))</Select.Option>
                                                        <Select.Option value="1800">1800 - sha512crypt $6$, SHA512 (Unix)</Select.Option>
                                                        <Select.Option value="2100">2100 - Domain Cached Credentials 2 (DCC2), MS Cache 2</Select.Option>
                                                        <Select.Option value="2400">2400 - Cisco-PIX MD5</Select.Option>
                                                        <Select.Option value="2410">2410 - Cisco-ASA MD5</Select.Option>
                                                        <Select.Option value="2500">2500 - WPA-EAPOL-PBKDF2</Select.Option>
                                                        <Select.Option value="2501">2501 - WPA-EAPOL-PMK</Select.Option>
                                                        <Select.Option value="2600">2600 - md5(md5($pass))</Select.Option>
                                                        <Select.Option value="2611">2611 - vBulletin &lt; v3.8.5</Select.Option>
                                                        <Select.Option value="2612">2612 - PHPS</Select.Option>
                                                        <Select.Option value="2711">2711 - vBulletin >= v3.8.5</Select.Option>
                                                        <Select.Option value="2811">2811 - MyBB 1.2+, IPB2+ (Invision Power Board)</Select.Option>
                                                        <Select.Option value="3000">3000 - LM</Select.Option>
                                                        <Select.Option value="3100">3100 - Oracle H: Type (Oracle 7+)</Select.Option>
                                                        <Select.Option value="3200">3200 - bcrypt $2*$, Blowfish (Unix)</Select.Option>
                                                        <Select.Option value="3500">3500 - md5(md5(md5($pass)))</Select.Option>
                                                        <Select.Option value="3710">3710 - md5($salt.md5($pass))</Select.Option>
                                                        <Select.Option value="3711">3711 - MediaWiki B type</Select.Option>
                                                        <Select.Option value="3730">3730 - md5($salt1.strtoupper(md5($salt2.$pass)))</Select.Option>
                                                        <Select.Option value="3800">3800 - md5($salt.$pass.$salt)</Select.Option>
                                                        <Select.Option value="3910">3910 - md5(md5($pass).md5($salt))</Select.Option>
                                                        <Select.Option value="4010">4010 - md5($salt.md5($salt.$pass))</Select.Option>
                                                        <Select.Option value="4110">4110 - md5($salt.md5($pass.$salt))</Select.Option>
                                                        <Select.Option value="4300">4300 - md5(strtoupper(md5($pass)))</Select.Option>
                                                        <Select.Option value="4400">4400 - md5(sha1($pass))</Select.Option>
                                                        <Select.Option value="4410">4410 - md5(sha1($pass).$salt)</Select.Option>
                                                        <Select.Option value="4500">4500 - sha1(sha1($pass))</Select.Option>
                                                        <Select.Option value="4501">4501 - sha1(sha1($pass).0x0d) / SHA1SHA1HUMx01</Select.Option>
                                                        <Select.Option value="4510">4510 - sha1(sha1($pass).$salt)</Select.Option>
                                                        <Select.Option value="4520">4520 - sha1($salt.sha1($pass))</Select.Option>
                                                        <Select.Option value="4521">4521 - Redmine</Select.Option>
                                                        <Select.Option value="4522">4522 - PunBB</Select.Option>
                                                        <Select.Option value="4700">4700 - sha1(md5($pass))</Select.Option>
                                                        <Select.Option value="4710">4710 - sha1(md5($pass).$salt)</Select.Option>
                                                        <Select.Option value="4711">4711 - Huawei sha1(md5($pass).$salt)</Select.Option>
                                                        <Select.Option value="4800">4800 - iSCSI CHAP authentication, MD5(CHAP)</Select.Option>
                                                        <Select.Option value="4900">4900 - sha1($salt.$pass.$salt)</Select.Option>
                                                        <Select.Option value="5000">5000 - sha1(sha1($salt.$pass.$salt))</Select.Option>
                                                        <Select.Option value="5100">5100 - Half MD5</Select.Option>
                                                        <Select.Option value="5200">5200 - Password Safe v3</Select.Option>
                                                        <Select.Option value="5300">5300 - IKE-PSK MD5</Select.Option>
                                                        <Select.Option value="5400">5400 - IKE-PSK SHA1</Select.Option>
                                                        <Select.Option value="5500">5500 - NetNTLMv1 / NetNTLMv1+ESS</Select.Option>
                                                        <Select.Option value="5600">5600 - NetNTLMv2</Select.Option>
                                                        <Select.Option value="5700">5700 - Cisco-IOS type 4 (SHA256)</Select.Option>
                                                        <Select.Option value="5800">5800 - Samsung Android Password/PIN</Select.Option>
                                                        <Select.Option value="6000">6000 - RIPEMD-160</Select.Option>
                                                        <Select.Option value="6050">6050 - HMAC-RIPEMD160 (key = $pass)</Select.Option>
                                                        <Select.Option value="6060">6060 - HMAC-RIPEMD160 (key = $salt)</Select.Option>
                                                        <Select.Option value="6100">6100 - Whirlpool</Select.Option>
                                                        <Select.Option value="6211">6211 - TrueCrypt RIPEMD160 + XTS 512 bit (legacy)</Select.Option>
                                                        <Select.Option value="6212">6212 - TrueCrypt RIPEMD160 + XTS 1024 bit (legacy)</Select.Option>
                                                        <Select.Option value="6213">6213 - TrueCrypt RIPEMD160 + XTS 1536 bit (legacy)</Select.Option>
                                                        <Select.Option value="6221">6221 - TrueCrypt SHA512 + XTS 512 bit (legacy)</Select.Option>
                                                        <Select.Option value="6222">6222 - TrueCrypt SHA512 + XTS 1024 bit (legacy)</Select.Option>
                                                        <Select.Option value="6223">6223 - TrueCrypt SHA512 + XTS 1536 bit (legacy)</Select.Option>
                                                        <Select.Option value="6231">6231 - TrueCrypt Whirlpool + XTS 512 bit (legacy)</Select.Option>
                                                        <Select.Option value="6232">6232 - TrueCrypt Whirlpool + XTS 1024 bit (legacy)</Select.Option>
                                                        <Select.Option value="6233">6233 - TrueCrypt Whirlpool + XTS 1536 bit (legacy)</Select.Option>
                                                        <Select.Option value="6241">6241 - TrueCrypt RIPEMD160 + XTS 512 bit + boot-mode (legacy)</Select.Option>
                                                        <Select.Option value="6242">6242 - TrueCrypt RIPEMD160 + XTS 1024 bit + boot-mode (legacy)</Select.Option>
                                                        <Select.Option value="6243">6243 - TrueCrypt RIPEMD160 + XTS 1536 bit + boot-mode (legacy)</Select.Option>
                                                        <Select.Option value="6300">6300 - AIX smd5</Select.Option>
                                                        <Select.Option value="6400">6400 - AIX ssha256</Select.Option>
                                                        <Select.Option value="6500">6500 - AIX ssha512</Select.Option>
                                                        <Select.Option value="6600">6600 - 1Password, agilekeychain</Select.Option>
                                                        <Select.Option value="6700">6700 - AIX ssha1</Select.Option>
                                                        <Select.Option value="6800">6800 - LastPass + LastPass sniffed</Select.Option>
                                                        <Select.Option value="6900">6900 - GOST R 34.11-94</Select.Option>
                                                        <Select.Option value="7000">7000 - FortiGate (FortiOS)</Select.Option>
                                                        <Select.Option value="7100">7100 - macOS v10.8+ (PBKDF2-SHA512)</Select.Option>
                                                        <Select.Option value="7200">7200 - GRUB 2</Select.Option>
                                                        <Select.Option value="7300">7300 - IPMI2 RAKP HMAC-SHA1</Select.Option>
                                                        <Select.Option value="7350">7350 - IPMI2 RAKP HMAC-MD5</Select.Option>
                                                        <Select.Option value="7400">7400 - sha256crypt $5$, SHA256 (Unix)</Select.Option>
                                                        <Select.Option value="7401">7401 - MySQL $A$ (sha256crypt)</Select.Option>
                                                        <Select.Option value="7500">7500 - Kerberos 5, etype 23, AS-REQ Pre-Auth</Select.Option>
                                                        <Select.Option value="7700">7700 - SAP CODVN B (BCODE)</Select.Option>
                                                        <Select.Option value="7701">7701 - SAP CODVN B (BCODE) from RFC_READ_TABLE</Select.Option>
                                                        <Select.Option value="7800">7800 - SAP CODVN F/G (PASSCODE)</Select.Option>
                                                        <Select.Option value="7801">7801 - SAP CODVN F/G (PASSCODE) from RFC_READ_TABLE</Select.Option>
                                                        <Select.Option value="7900">7900 - Drupal7</Select.Option>
                                                        <Select.Option value="8000">8000 - Sybase ASE</Select.Option>
                                                        <Select.Option value="8100">8100 - Citrix NetScaler (SHA1)</Select.Option>
                                                        <Select.Option value="8200">8200 - 1Password, cloudkeychain</Select.Option>
                                                        <Select.Option value="8300">8300 - DNSSEC (NSEC3)</Select.Option>
                                                        <Select.Option value="8400">8400 - WBB3 (Woltlab Burning Board)</Select.Option>
                                                        <Select.Option value="8500">8500 - RACF</Select.Option>
                                                        <Select.Option value="8600">8600 - Lotus Notes/Domino 5</Select.Option>
                                                        <Select.Option value="8700">8700 - Lotus Notes/Domino 6</Select.Option>
                                                        <Select.Option value="8800">8800 - Android FDE &lt;= 4.3</Select.Option>
                                                        <Select.Option value="8900">8900 - scrypt</Select.Option>
                                                        <Select.Option value="9000">9000 - Password Safe v2</Select.Option>
                                                        <Select.Option value="9100">9100 - Lotus Notes/Domino 8</Select.Option>
                                                        <Select.Option value="9200">9200 - Cisco-IOS $8$ (PBKDF2-SHA256)</Select.Option>
                                                        <Select.Option value="9300">9300 - Cisco-IOS $9$ (scrypt)</Select.Option>
                                                        <Select.Option value="9400">9400 - MS Office 2007</Select.Option>
                                                        <Select.Option value="9500">9500 - MS Office 2010</Select.Option>
                                                        <Select.Option value="9600">9600 - MS Office 2013</Select.Option>
                                                        <Select.Option value="9700">9700 - MS Office &lt;= 2003 $0/$1, MD5 + RC4</Select.Option>
                                                        <Select.Option value="9710">9710 - MS Office &lt;= 2003 $0/$1, MD5 + RC4, collider #1</Select.Option>
                                                        <Select.Option value="9720">9720 - MS Office &lt;= 2003 $0/$1, MD5 + RC4, collider #2</Select.Option>
                                                        <Select.Option value="9800">9800 - MS Office &lt;= 2003 $3/$4, SHA1 + RC4</Select.Option>
                                                        <Select.Option value="9810">9810 - MS Office &lt;= 2003 $3, SHA1 + RC4, collider #1</Select.Option>
                                                        <Select.Option value="9820">9820 - MS Office &lt;= 2003 $3, SHA1 + RC4, collider #2</Select.Option>
                                                        <Select.Option value="9900">9900 - Radmin2</Select.Option>
                                                        <Select.Option value="10000">10000 - Django (PBKDF2-SHA256)</Select.Option>
                                                        <Select.Option value="10100">10100 - SipHash</Select.Option>
                                                        <Select.Option value="10200">10200 - CRAM-MD5</Select.Option>
                                                        <Select.Option value="10300">10300 - SAP CODVN H (PWDSALTEDHASH) iSSHA-1</Select.Option>
                                                        <Select.Option value="10400">10400 - PDF 1.1 - 1.3 (Acrobat 2 - 4)</Select.Option>
                                                        <Select.Option value="10410">10410 - PDF 1.1 - 1.3 (Acrobat 2 - 4), collider #1</Select.Option>
                                                        <Select.Option value="10420">10420 - PDF 1.1 - 1.3 (Acrobat 2 - 4), collider #2</Select.Option>
                                                        <Select.Option value="10500">10500 - PDF 1.4 - 1.6 (Acrobat 5 - 8)</Select.Option>
                                                        <Select.Option value="10600">10600 - PDF 1.7 Level 3 (Acrobat 9)</Select.Option>
                                                        <Select.Option value="10700">10700 - PDF 1.7 Level 8 (Acrobat 10 - 11)</Select.Option>
                                                        <Select.Option value="10800">10800 - SHA2-384</Select.Option>
                                                        <Select.Option value="10810">10810 - sha384($pass.$salt)</Select.Option>
                                                        <Select.Option value="10820">10820 - sha384($salt.$pass)</Select.Option>
                                                        <Select.Option value="10830">10830 - sha384(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="10840">10840 - sha384($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="10870">10870 - sha384(utf16le($pass))</Select.Option>
                                                        <Select.Option value="10900">10900 - PBKDF2-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="10901">10901 - RedHat 389-DS LDAP (PBKDF2-HMAC-SHA256)</Select.Option>
                                                        <Select.Option value="11000">11000 - PrestaShop</Select.Option>
                                                        <Select.Option value="11100">11100 - PostgreSQL CRAM (MD5)</Select.Option>
                                                        <Select.Option value="11200">11200 - MySQL CRAM (SHA1)</Select.Option>
                                                        <Select.Option value="11300">11300 - Bitcoin/Litecoin wallet.dat</Select.Option>
                                                        <Select.Option value="11400">11400 - SIP digest authentication (MD5)</Select.Option>
                                                        <Select.Option value="11500">11500 - CRC32</Select.Option>
                                                        <Select.Option value="11600">11600 - 7-Zip</Select.Option>
                                                        <Select.Option value="11700">11700 - GOST R 34.11-2012 (Streebog) 256-bit, big-endian</Select.Option>
                                                        <Select.Option value="11750">11750 - HMAC-Streebog-256 (key = $pass), big-endian</Select.Option>
                                                        <Select.Option value="11760">11760 - HMAC-Streebog-256 (key = $salt), big-endian</Select.Option>
                                                        <Select.Option value="11800">11800 - GOST R 34.11-2012 (Streebog) 512-bit, big-endian</Select.Option>
                                                        <Select.Option value="11850">11850 - HMAC-Streebog-512 (key = $pass), big-endian</Select.Option>
                                                        <Select.Option value="11860">11860 - HMAC-Streebog-512 (key = $salt), big-endian</Select.Option>
                                                        <Select.Option value="11900">11900 - PBKDF2-HMAC-MD5</Select.Option>
                                                        <Select.Option value="12001">12001 - Atlassian (PBKDF2-HMAC-SHA1)</Select.Option>
                                                        <Select.Option value="12100">12100 - PBKDF2-HMAC-SHA512</Select.Option>
                                                        <Select.Option value="12200">12200 - eCryptfs</Select.Option>
                                                        <Select.Option value="12300">12300 - Oracle T: Type (Oracle 12+)</Select.Option>
                                                        <Select.Option value="12400">12400 - BSDi Crypt, Extended DES</Select.Option>
                                                        <Select.Option value="12500">12500 - RAR3-hp</Select.Option>
                                                        <Select.Option value="12600">12600 - ColdFusion 10+</Select.Option>
                                                        <Select.Option value="12700">12700 - Blockchain, My Wallet</Select.Option>
                                                        <Select.Option value="12800">12800 - MS-AzureSync PBKDF2-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="12900">12900 - Android FDE (Samsung DEK)</Select.Option>
                                                        <Select.Option value="13000">13000 - RAR5</Select.Option>
                                                        <Select.Option value="13100">13100 - Kerberos 5, etype 23, TGS-REP</Select.Option>
                                                        <Select.Option value="13200">13200 - AxCrypt 1</Select.Option>
                                                        <Select.Option value="13300">13300 - AxCrypt 1 in-memory SHA1</Select.Option>
                                                        <Select.Option value="13400">13400 - KeePass 1 (AES/Twofish) and KeePass 2 (AES)</Select.Option>
                                                        <Select.Option value="13500">13500 - PeopleSoft PS_TOKEN</Select.Option>
                                                        <Select.Option value="13600">13600 - WinZip</Select.Option>
                                                        <Select.Option value="13800">13800 - Windows Phone 8+ PIN/password</Select.Option>
                                                        <Select.Option value="13900">13900 - OpenCart</Select.Option>
                                                        <Select.Option value="14000">14000 - DES (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="14100">14100 - 3DES (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="14400">14400 - sha1(CX)</Select.Option>
                                                        <Select.Option value="14500">14500 - Linux Kernel Crypto API (2.4)</Select.Option>
                                                        <Select.Option value="14600">14600 - LUKS v1 (legacy)</Select.Option>
                                                        <Select.Option value="14700">14700 - iTunes backup &lt; 10.0</Select.Option>
                                                        <Select.Option value="14800">14800 - iTunes backup >= 10.0</Select.Option>
                                                        <Select.Option value="14900">14900 - Skip32 (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="15000">15000 - FileZilla Server >= 0.9.55</Select.Option>
                                                        <Select.Option value="15100">15100 - Juniper/NetBSD sha1crypt</Select.Option>
                                                        <Select.Option value="15200">15200 - Blockchain, My Wallet, V2</Select.Option>
                                                        <Select.Option value="15300">15300 - DPAPI masterkey file v1 (context 1 and 2)</Select.Option>
                                                        <Select.Option value="15310">15310 - DPAPI masterkey file v1 (context 3)</Select.Option>
                                                        <Select.Option value="15400">15400 - ChaCha20</Select.Option>
                                                        <Select.Option value="15500">15500 - JKS Java Key Store Private Keys (SHA1)</Select.Option>
                                                        <Select.Option value="15600">15600 - Ethereum Wallet, PBKDF2-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="15700">15700 - Ethereum Wallet, SCRYPT</Select.Option>
                                                        <Select.Option value="15900">15900 - DPAPI masterkey file v2 (context 1 and 2)</Select.Option>
                                                        <Select.Option value="15910">15910 - DPAPI masterkey file v2 (context 3)</Select.Option>
                                                        <Select.Option value="16000">16000 - Tripcode</Select.Option>
                                                        <Select.Option value="16100">16100 - TACACS+</Select.Option>
                                                        <Select.Option value="16200">16200 - Apple Secure Notes</Select.Option>
                                                        <Select.Option value="16300">16300 - Ethereum Pre-Sale Wallet, PBKDF2-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="16400">16400 - CRAM-MD5 Dovecot</Select.Option>
                                                        <Select.Option value="16500">16500 - JWT (JSON Web Token)</Select.Option>
                                                        <Select.Option value="16600">16600 - Electrum Wallet (Salt-Type 1-3)</Select.Option>
                                                        <Select.Option value="16700">16700 - FileVault 2</Select.Option>
                                                        <Select.Option value="16800">16800 - WPA-PMKID-PBKDF2</Select.Option>
                                                        <Select.Option value="16801">16801 - WPA-PMKID-PMK</Select.Option>
                                                        <Select.Option value="16900">16900 - Ansible Vault</Select.Option>
                                                        <Select.Option value="17010">17010 - GPG (AES-128/AES-256 (SHA-1($pass)))</Select.Option>
                                                        <Select.Option value="17020">17020 - GPG (AES-128/AES-256 (SHA-512($pass)))</Select.Option>
                                                        <Select.Option value="17030">17030 - GPG (AES-128/AES-256 (SHA-256($pass)))</Select.Option>
                                                        <Select.Option value="17200">17200 - PKZIP (Compressed)</Select.Option>
                                                        <Select.Option value="17210">17210 - PKZIP (Uncompressed)</Select.Option>
                                                        <Select.Option value="17220">17220 - PKZIP (Compressed Multi-File)</Select.Option>
                                                        <Select.Option value="17225">17225 - PKZIP (Mixed Multi-File)</Select.Option>
                                                        <Select.Option value="17230">17230 - PKZIP (Mixed Multi-File Checksum-Only)</Select.Option>
                                                        <Select.Option value="17300">17300 - SHA3-224</Select.Option>
                                                        <Select.Option value="17400">17400 - SHA3-256</Select.Option>
                                                        <Select.Option value="17500">17500 - SHA3-384</Select.Option>
                                                        <Select.Option value="17600">17600 - SHA3-512</Select.Option>
                                                        <Select.Option value="17700">17700 - Keccak-224</Select.Option>
                                                        <Select.Option value="17800">17800 - Keccak-256</Select.Option>
                                                        <Select.Option value="17900">17900 - Keccak-384</Select.Option>
                                                        <Select.Option value="18000">18000 - Keccak-512</Select.Option>
                                                        <Select.Option value="18100">18100 - TOTP (HMAC-SHA1)</Select.Option>
                                                        <Select.Option value="18200">18200 - Kerberos 5, etype 23, AS-REP</Select.Option>
                                                        <Select.Option value="18300">18300 - Apple File System (APFS)</Select.Option>
                                                        <Select.Option value="18400">18400 - Open Document Format (ODF) 1.2 (SHA-256, AES)</Select.Option>
                                                        <Select.Option value="18500">18500 - sha1(md5(md5($pass)))</Select.Option>
                                                        <Select.Option value="18501">18501 - sha1(md5(sha1($pass)))</Select.Option>
                                                        <Select.Option value="18600">18600 - Open Document Format (ODF) 1.1 (SHA-1, Blowfish)</Select.Option>
                                                        <Select.Option value="18700">18700 - Java Object hashCode()</Select.Option>
                                                        <Select.Option value="18800">18800 - Blockchain, My Wallet, Second Password (SHA256)</Select.Option>
                                                        <Select.Option value="18900">18900 - Android Backup</Select.Option>
                                                        <Select.Option value="19000">19000 - QNX /etc/shadow (MD5)</Select.Option>
                                                        <Select.Option value="19100">19100 - QNX /etc/shadow (SHA256)</Select.Option>
                                                        <Select.Option value="19200">19200 - QNX /etc/shadow (SHA512)</Select.Option>
                                                        <Select.Option value="19300">19300 - sha1($salt1.$pass.$salt2)</Select.Option>
                                                        <Select.Option value="19500">19500 - Ruby on Rails Restful-Authentication</Select.Option>
                                                        <Select.Option value="19600">19600 - Kerberos 5, etype 17, TGS-REP</Select.Option>
                                                        <Select.Option value="19700">19700 - Kerberos 5, etype 18, TGS-REP</Select.Option>
                                                        <Select.Option value="19800">19800 - Kerberos 5, etype 17, Pre-Auth</Select.Option>
                                                        <Select.Option value="19900">19900 - Kerberos 5, etype 18, Pre-Auth</Select.Option>
                                                        <Select.Option value="20011">20011 - DiskCryptor SHA512 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="20012">20012 - DiskCryptor SHA512 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="20013">20013 - DiskCryptor SHA512 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="20200">20200 - Python passlib pbkdf2-sha512</Select.Option>
                                                        <Select.Option value="20300">20300 - Python passlib pbkdf2-sha256</Select.Option>
                                                        <Select.Option value="20400">20400 - Python passlib pbkdf2-sha1</Select.Option>
                                                        <Select.Option value="20500">20500 - PKZIP Master Key</Select.Option>
                                                        <Select.Option value="20510">20510 - PKZIP Master Key (6 byte optimization)</Select.Option>
                                                        <Select.Option value="20600">20600 - Oracle Transportation Management (SHA256)</Select.Option>
                                                        <Select.Option value="20710">20710 - sha256(sha256($pass).$salt)</Select.Option>
                                                        <Select.Option value="20711">20711 - AuthMe sha256</Select.Option>
                                                        <Select.Option value="20720">20720 - sha256($salt.sha256($pass))</Select.Option>
                                                        <Select.Option value="20800">20800 - sha256(md5($pass))</Select.Option>
                                                        <Select.Option value="20900">20900 - md5(sha1($pass).md5($pass).sha1($pass))</Select.Option>
                                                        <Select.Option value="21000">21000 - BitShares v0.x - sha512(sha512_bin(pass))</Select.Option>
                                                        <Select.Option value="21100">21100 - sha1(md5($pass.$salt))</Select.Option>
                                                        <Select.Option value="21200">21200 - md5(sha1($salt).md5($pass))</Select.Option>
                                                        <Select.Option value="21300">21300 - md5($salt.sha1($salt.$pass))</Select.Option>
                                                        <Select.Option value="21400">21400 - sha256(sha256_bin($pass))</Select.Option>
                                                        <Select.Option value="21420">21420 - sha256($salt.sha256_bin($pass))</Select.Option>
                                                        <Select.Option value="21500">21500 - SolarWinds Orion</Select.Option>
                                                        <Select.Option value="21501">21501 - SolarWinds Orion v2</Select.Option>
                                                        <Select.Option value="21600">21600 - Web2py pbkdf2-sha512</Select.Option>
                                                        <Select.Option value="21700">21700 - Electrum Wallet (Salt-Type 4)</Select.Option>
                                                        <Select.Option value="21800">21800 - Electrum Wallet (Salt-Type 5)</Select.Option>
                                                        <Select.Option value="22000">22000 - WPA-PBKDF2-PMKID+EAPOL</Select.Option>
                                                        <Select.Option value="22001">22001 - WPA-PMK-PMKID+EAPOL</Select.Option>
                                                        <Select.Option value="22100">22100 - BitLocker</Select.Option>
                                                        <Select.Option value="22200">22200 - Citrix NetScaler (SHA512)</Select.Option>
                                                        <Select.Option value="22300">22300 - sha256($salt.$pass.$salt)</Select.Option>
                                                        <Select.Option value="22301">22301 - Telegram Mobile App Passcode (SHA256)</Select.Option>
                                                        <Select.Option value="22400">22400 - AES Crypt (SHA256)</Select.Option>
                                                        <Select.Option value="22500">22500 - MultiBit Classic .key (MD5)</Select.Option>
                                                        <Select.Option value="22600">22600 - Telegram Desktop &lt; v2.1.14 (PBKDF2-HMAC-SHA1)</Select.Option>
                                                        <Select.Option value="22700">22700 - MultiBit HD (scrypt)</Select.Option>
                                                        <Select.Option value="22911">22911 - RSA/DSA/EC/OpenSSH Private Keys ($0$)</Select.Option>
                                                        <Select.Option value="22921">22921 - RSA/DSA/EC/OpenSSH Private Keys ($6$)</Select.Option>
                                                        <Select.Option value="22931">22931 - RSA/DSA/EC/OpenSSH Private Keys ($1, $3$)</Select.Option>
                                                        <Select.Option value="22941">22941 - RSA/DSA/EC/OpenSSH Private Keys ($4$)</Select.Option>
                                                        <Select.Option value="22951">22951 - RSA/DSA/EC/OpenSSH Private Keys ($5$)</Select.Option>
                                                        <Select.Option value="23001">23001 - SecureZIP AES-128</Select.Option>
                                                        <Select.Option value="23002">23002 - SecureZIP AES-192</Select.Option>
                                                        <Select.Option value="23003">23003 - SecureZIP AES-256</Select.Option>
                                                        <Select.Option value="23100">23100 - Apple Keychain</Select.Option>
                                                        <Select.Option value="23200">23200 - XMPP SCRAM PBKDF2-SHA1</Select.Option>
                                                        <Select.Option value="23300">23300 - Apple iWork</Select.Option>
                                                        <Select.Option value="23400">23400 - Bitwarden</Select.Option>
                                                        <Select.Option value="23500">23500 - AxCrypt 2 AES-128</Select.Option>
                                                        <Select.Option value="23600">23600 - AxCrypt 2 AES-256</Select.Option>
                                                        <Select.Option value="23700">23700 - RAR3-p (Uncompressed)</Select.Option>
                                                        <Select.Option value="23800">23800 - RAR3-p (Compressed)</Select.Option>
                                                        <Select.Option value="23900">23900 - BestCrypt v3 Volume Encryption</Select.Option>
                                                        <Select.Option value="24100">24100 - MongoDB ServerKey SCRAM-SHA-1</Select.Option>
                                                        <Select.Option value="24200">24200 - MongoDB ServerKey SCRAM-SHA-256</Select.Option>
                                                        <Select.Option value="24300">24300 - sha1($salt.sha1($pass.$salt))</Select.Option>
                                                        <Select.Option value="24410">24410 - PKCS#8 Private Keys (PBKDF2-HMAC-SHA1 + 3DES/AES)</Select.Option>
                                                        <Select.Option value="24420">24420 - PKCS#8 Private Keys (PBKDF2-HMAC-SHA256 + 3DES/AES)</Select.Option>
                                                        <Select.Option value="24500">24500 - Telegram Desktop >= v2.1.14 (PBKDF2-HMAC-SHA512)</Select.Option>
                                                        <Select.Option value="24600">24600 - SQLCipher</Select.Option>
                                                        <Select.Option value="24700">24700 - Stuffit5</Select.Option>
                                                        <Select.Option value="24800">24800 - Umbraco HMAC-SHA1</Select.Option>
                                                        <Select.Option value="24900">24900 - Dahua Authentication MD5</Select.Option>
                                                        <Select.Option value="25000">25000 - SNMPv3 HMAC-MD5-96/HMAC-SHA1-96</Select.Option>
                                                        <Select.Option value="25100">25100 - SNMPv3 HMAC-MD5-96</Select.Option>
                                                        <Select.Option value="25200">25200 - SNMPv3 HMAC-SHA1-96</Select.Option>
                                                        <Select.Option value="25300">25300 - MS Office 2016 - SheetProtection</Select.Option>
                                                        <Select.Option value="25400">25400 - PDF 1.4 - 1.6 (Acrobat 5 - 8) - user and owner pass</Select.Option>
                                                        <Select.Option value="25500">25500 - Stargazer Stellar Wallet XLM</Select.Option>
                                                        <Select.Option value="25600">25600 - bcrypt(md5($pass)) / bcryptmd5</Select.Option>
                                                        <Select.Option value="25700">25700 - MurmurHash</Select.Option>
                                                        <Select.Option value="25800">25800 - bcrypt(sha1($pass)) / bcryptsha1</Select.Option>
                                                        <Select.Option value="25900">25900 - KNX IP Secure - Device Authentication Code</Select.Option>
                                                        <Select.Option value="26000">26000 - Mozilla key3.db</Select.Option>
                                                        <Select.Option value="26100">26100 - Mozilla key4.db</Select.Option>
                                                        <Select.Option value="26200">26200 - OpenEdge Progress Encode</Select.Option>
                                                        <Select.Option value="26300">26300 - FortiGate256 (FortiOS256)</Select.Option>
                                                        <Select.Option value="26401">26401 - AES-128-ECB NOKDF (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="26402">26402 - AES-192-ECB NOKDF (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="26403">26403 - AES-256-ECB NOKDF (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="26500">26500 - iPhone passcode (UID key + System Keybag)</Select.Option>
                                                        <Select.Option value="26600">26600 - MetaMask Wallet (needs all data, checks AES-GCM tag)</Select.Option>
                                                        <Select.Option value="26610">26610 - MetaMask Wallet (short hash, plaintext check)</Select.Option>
                                                        <Select.Option value="26700">26700 - SNMPv3 HMAC-SHA224-128</Select.Option>
                                                        <Select.Option value="26800">26800 - SNMPv3 HMAC-SHA256-192</Select.Option>
                                                        <Select.Option value="26900">26900 - SNMPv3 HMAC-SHA384-256</Select.Option>
                                                        <Select.Option value="27000">27000 - NetNTLMv1 / NetNTLMv1+ESS (NT)</Select.Option>
                                                        <Select.Option value="27100">27100 - NetNTLMv2 (NT)</Select.Option>
                                                        <Select.Option value="27200">27200 - Ruby on Rails Restful Auth (one round, no sitekey)</Select.Option>
                                                        <Select.Option value="27300">27300 - SNMPv3 HMAC-SHA512-384</Select.Option>
                                                        <Select.Option value="27400">27400 - VMware VMX (PBKDF2-HMAC-SHA1 + AES-256-CBC)</Select.Option>
                                                        <Select.Option value="27500">27500 - VirtualBox (PBKDF2-HMAC-SHA256 & AES-128-XTS)</Select.Option>
                                                        <Select.Option value="27600">27600 - VirtualBox (PBKDF2-HMAC-SHA256 & AES-256-XTS)</Select.Option>
                                                        <Select.Option value="27700">27700 - MultiBit Classic .wallet (scrypt)</Select.Option>
                                                        <Select.Option value="27800">27800 - MurmurHash3</Select.Option>
                                                        <Select.Option value="27900">27900 - CRC32C</Select.Option>
                                                        <Select.Option value="28000">28000 - CRC64Jones</Select.Option>
                                                        <Select.Option value="28100">28100 - Windows Hello PIN/Password</Select.Option>
                                                        <Select.Option value="28200">28200 - Exodus Desktop Wallet (scrypt)</Select.Option>
                                                        <Select.Option value="28300">28300 - Teamspeak 3 (channel hash)</Select.Option>
                                                        <Select.Option value="28400">28400 - bcrypt(sha512($pass)) / bcryptsha512</Select.Option>
                                                        <Select.Option value="28501">28501 - Bitcoin WIF private key (P2PKH), compressed</Select.Option>
                                                        <Select.Option value="28502">28502 - Bitcoin WIF private key (P2PKH), uncompressed</Select.Option>
                                                        <Select.Option value="28503">28503 - Bitcoin WIF private key (P2WPKH, Bech32), compressed</Select.Option>
                                                        <Select.Option value="28504">28504 - Bitcoin WIF private key (P2WPKH, Bech32), uncompressed</Select.Option>
                                                        <Select.Option value="28505">28505 - Bitcoin WIF private key (P2SH(P2WPKH)), compressed</Select.Option>
                                                        <Select.Option value="28506">28506 - Bitcoin WIF private key (P2SH(P2WPKH)), uncompressed</Select.Option>
                                                        <Select.Option value="28600">28600 - PostgreSQL SCRAM-SHA-256</Select.Option>
                                                        <Select.Option value="28700">28700 - Amazon AWS4-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="28800">28800 - Kerberos 5, etype 17, DB</Select.Option>
                                                        <Select.Option value="28900">28900 - Kerberos 5, etype 18, DB</Select.Option>
                                                        <Select.Option value="29000">29000 - sha1($salt.sha1(utf16le($username).':'.utf16le($pass)))</Select.Option>
                                                        <Select.Option value="29100">29100 - Flask Session Cookie ($salt.$salt.$pass)</Select.Option>
                                                        <Select.Option value="29200">29200 - Radmin3</Select.Option>
                                                        <Select.Option value="29311">29311 - TrueCrypt RIPEMD160 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29312">29312 - TrueCrypt RIPEMD160 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29313">29313 - TrueCrypt RIPEMD160 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29321">29321 - TrueCrypt SHA512 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29322">29322 - TrueCrypt SHA512 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29323">29323 - TrueCrypt SHA512 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29331">29331 - TrueCrypt Whirlpool + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29332">29332 - TrueCrypt Whirlpool + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29333">29333 - TrueCrypt Whirlpool + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29341">29341 - TrueCrypt RIPEMD160 + XTS 512 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29342">29342 - TrueCrypt RIPEMD160 + XTS 1024 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29343">29343 - TrueCrypt RIPEMD160 + XTS 1536 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29411">29411 - VeraCrypt RIPEMD160 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29412">29412 - VeraCrypt RIPEMD160 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29413">29413 - VeraCrypt RIPEMD160 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29421">29421 - VeraCrypt SHA512 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29422">29422 - VeraCrypt SHA512 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29423">29423 - VeraCrypt SHA512 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29431">29431 - VeraCrypt Whirlpool + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29432">29432 - VeraCrypt Whirlpool + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29433">29433 - VeraCrypt Whirlpool + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29441">29441 - VeraCrypt RIPEMD160 + XTS 512 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29442">29442 - VeraCrypt RIPEMD160 + XTS 1024 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29443">29443 - VeraCrypt RIPEMD160 + XTS 1536 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29451">29451 - VeraCrypt SHA256 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29452">29452 - VeraCrypt SHA256 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29453">29453 - VeraCrypt SHA256 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29461">29461 - VeraCrypt SHA256 + XTS 512 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29462">29462 - VeraCrypt SHA256 + XTS 1024 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29463">29463 - VeraCrypt SHA256 + XTS 1536 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29471">29471 - VeraCrypt Streebog-512 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29472">29472 - VeraCrypt Streebog-512 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29473">29473 - VeraCrypt Streebog-512 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29481">29481 - VeraCrypt Streebog-512 + XTS 512 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29482">29482 - VeraCrypt Streebog-512 + XTS 1024 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29483">29483 - VeraCrypt Streebog-512 + XTS 1536 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29511">29511 - LUKS v1 SHA-1 + AES</Select.Option>
                                                        <Select.Option value="29512">29512 - LUKS v1 SHA-1 + Serpent</Select.Option>
                                                        <Select.Option value="29513">29513 - LUKS v1 SHA-1 + Twofish</Select.Option>
                                                        <Select.Option value="29521">29521 - LUKS v1 SHA-256 + AES</Select.Option>
                                                        <Select.Option value="29522">29522 - LUKS v1 SHA-256 + Serpent</Select.Option>
                                                        <Select.Option value="29523">29523 - LUKS v1 SHA-256 + Twofish</Select.Option>
                                                        <Select.Option value="29531">29531 - LUKS v1 SHA-512 + AES</Select.Option>
                                                        <Select.Option value="29532">29532 - LUKS v1 SHA-512 + Serpent</Select.Option>
                                                        <Select.Option value="29533">29533 - LUKS v1 SHA-512 + Twofish</Select.Option>
                                                        <Select.Option value="29541">29541 - LUKS v1 RIPEMD-160 + AES</Select.Option>
                                                        <Select.Option value="29542">29542 - LUKS v1 RIPEMD-160 + Serpent</Select.Option>
                                                        <Select.Option value="29543">29543 - LUKS v1 RIPEMD-160 + Twofish</Select.Option>
                                                        <Select.Option value="29600">29600 - Terra Station Wallet (AES256-CBC(PBKDF2($pass)))</Select.Option>
                                                        <Select.Option value="29700">29700 - KeePass 1 (AES/Twofish) and KeePass 2 (AES) - keyfile only mode</Select.Option>
                                                        <Select.Option value="29800">29800 - Bisq .wallet (scrypt)</Select.Option>
                                                        <Select.Option value="29910">29910 - ENCsecurity Datavault (PBKDF2/no keychain)</Select.Option>
                                                        <Select.Option value="29920">29920 - ENCsecurity Datavault (PBKDF2/keychain)</Select.Option>
                                                        <Select.Option value="29930">29930 - ENCsecurity Datavault (MD5/no keychain)</Select.Option>
                                                        <Select.Option value="29940">29940 - ENCsecurity Datavault (MD5/keychain)</Select.Option>
                                                        <Select.Option value="30000">30000 - Python Werkzeug MD5 (HMAC-MD5 (key = $salt))</Select.Option>
                                                        <Select.Option value="30120">30120 - Python Werkzeug SHA256 (HMAC-SHA256 (key = $salt))</Select.Option>
                                                        <Select.Option value="30420">30420 - DANE RFC7929/RFC8162 SHA2-256</Select.Option>
                                                        <Select.Option value="30500">30500 - md5(md5($salt).md5(md5($pass)))</Select.Option>
                                                        <Select.Option value="30600">30600 - bcrypt(sha256($pass)) / bcryptsha256</Select.Option>
                                                        <Select.Option value="30700">30700 - Anope IRC Services (enc_sha256)</Select.Option>
                                                        <Select.Option value="30901">30901 - Bitcoin raw private key (P2PKH), compressed</Select.Option>
                                                        <Select.Option value="30902">30902 - Bitcoin raw private key (P2PKH), uncompressed</Select.Option>
                                                        <Select.Option value="30903">30903 - Bitcoin raw private key (P2WPKH, Bech32), compressed</Select.Option>
                                                        <Select.Option value="30904">30904 - Bitcoin raw private key (P2WPKH, Bech32), uncompressed</Select.Option>
                                                        <Select.Option value="30905">30905 - Bitcoin raw private key (P2SH(P2WPKH)), compressed</Select.Option>
                                                        <Select.Option value="30906">30906 - Bitcoin raw private key (P2SH(P2WPKH)), uncompressed</Select.Option>
                                                        <Select.Option value="31000">31000 - BLAKE2s-256</Select.Option>
                                                        <Select.Option value="31100">31100 - SM3</Select.Option>
                                                        <Select.Option value="31200">31200 - Veeam VBK</Select.Option>
                                                        <Select.Option value="31300">31300 - MS SNTP</Select.Option>
                                                        <Select.Option value="31400">31400 - SecureCRT MasterPassphrase v2</Select.Option>
                                                        <Select.Option value="31700">31700 - md5(md5(md5($pass).$salt1).$salt2)</Select.Option>
                                                        <Select.Option value="31800">31800 - 1Password, mobilekeychain (1Password 8)</Select.Option>
                                                        <Select.Option value="31900">31900 - MetaMask Mobile Wallet</Select.Option>
                                                    </Select>
                                                    <Upload
                                                        {...(index === 0) ? props : ""}
                                                        {...(index === 1) ? props2 : ""}
                                                        {...(index === 2) ? props3 : ""}
                                                        {...(index === 3) ? props4 : ""}
                                                        {...(index === 4) ? props5 : ""}
                                                        style={{ width: '40%' }}>
                                                        <Button icon={<UploadOutlined />}>Attach file</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    if(fields.length < 5) {
                                                        add();
                                                    } else {
                                                        message.error("Cannot add more than 5 algorithms to one breach. If more are required, contact support.")
                                                    }
                                                }}
                                                style={{ width: '60%' }}
                                                icon={<PlusOutlined />}
                                            >
                                                Create New
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            </Form.Item>
                        </Form>
                        <Button type={"primary"} style={{width:"100%"}} onClick={doSubmit}>Add Algorithms</Button>
                    </Card>
                    </Spin>
                </Col>
                <Col span={18} offset={3}>
                    <Card title={"File Preview"}>
                        <TextArea rows={20} value={previewData}/>
                    </Card>
                </Col>
                {leakData['status'] === "open" && <>
                    <Col span={9} offset={3} style={{paddingRight: '10px'}}>
                        <Button style={{width:'100%', backgroundColor: 'red'}} onClick={() => { declineSubmission() }}>Decline</Button>
                    </Col>
                    <Col span={9}>
                        <Button style={{width:'100%', backgroundColor: 'green'}} onClick={() => { acceptSubmission() }}>Accept</Button>
                    </Col>
                </>}
                {leakData['status'] !== "open" && <>
                    <Col span={18} offset={3} style={{paddingRight: '10px'}}>
                        <Button style={{width:'100%', backgroundColor: 'gold', color:'black', fontWeight:'bold'}} onClick={() => { openSubmission() }}>Re-Open</Button>
                    </Col>
                </>}
            </Row>
        </Content>
    );
};

export default AdminSubmissionView;