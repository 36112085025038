import React, { useState } from 'react';
import { ConfigProvider, Layout, Card, theme, Row, Col, DatePicker, Cascader, Select } from 'antd';
import { InfoCircleOutlined, UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio, Upload, message, Modal } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
    },
};

const SubmitLeak: React.FC = () => {
    const [form] = Form.useForm();
    const [dataBreachUploadList, setDataBreachUploadList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [fileList2, setFileList2] = useState([]);
    const [fileList3, setFileList3] = useState([]);
    const [fileList4, setFileList4] = useState([]);
    const [fileList5, setFileList5] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [industries, setIndustries] = useState([]);
    const [successUploaded, setSuccessUploaded] = useState(false);
    const [algorithms, setAlgorithms] = useState([undefined, undefined, undefined, undefined, undefined]);

    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
        disableCheckbox?: boolean;
    }

    const breachUpload: UploadProps = {
        onRemove: (file) => {
            const index = dataBreachUploadList.indexOf(file);
            const newFileList = dataBreachUploadList.slice();
            newFileList.splice(index, 1);
            setDataBreachUploadList(newFileList);
        },
        beforeUpload: (file) => {
            setDataBreachUploadList([...dataBreachUploadList, file]);

            return false;
        },
        dataBreachUploadList,
    };

    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    const props2: UploadProps = {
        onRemove: (file) => {
            const index = fileList2.indexOf(file);
            const newFileList = fileList2.slice();
            newFileList.splice(index, 1);
            setFileList2(newFileList);
        },
        beforeUpload: (file) => {
            setFileList2([...fileList2, file]);

            return false;
        },
        fileList2,
    };

    const props3: UploadProps = {
        onRemove: (file) => {
            const index = fileList3.indexOf(file);
            const newFileList = fileList3.slice();
            newFileList.splice(index, 1);
            setFileList3(newFileList);
        },
        beforeUpload: (file) => {
            setFileList3([...fileList3, file]);

            return false;
        },
        fileList3,
    };

    const props4: UploadProps = {
        onRemove: (file) => {
            const index = fileList4.indexOf(file);
            const newFileList = fileList4.slice();
            newFileList.splice(index, 1);
            setFileList4(newFileList);
        },
        beforeUpload: (file) => {
            setFileList4([...fileList4, file]);

            return false;
        },
        fileList4,
    };

    const props5: UploadProps = {
        onRemove: (file) => {
            const index = fileList5.indexOf(file);
            const newFileList = fileList5.slice();
            newFileList.splice(index, 1);
            setFileList5(newFileList);
        },
        beforeUpload: (file) => {
            setFileList5([...fileList5, file]);

            return false;
        },
        fileList5,
    };

    const industry_sectors: Option[] = [
        {
            label: 'Commonly Hacked',
            value: 'Commonly Hacked',
            children: [
                {
                    label: 'Anime / Manga / Comics / etc..',
                    value: 'Anime / Manga / Comics / etc..',
                },
                {
                    label: 'Booter',
                    value: 'Booter',
                },
                {
                    label: 'Carding',
                    value: 'Carding',
                },
                {
                    label: 'Cryptocurrency',
                    value: 'Cryptocurrency',
                },
                {
                    label: 'Dating',
                    value: 'Dating',
                },
                {
                    label: 'Education',
                    value: 'Education',
                },
                {
                    label: 'Energy and Utilities',
                    value: 'Energy and Utilities',
                },
                {
                    label: 'Financial Services',
                    value: 'Financial Services',
                },
                {
                    label: 'Gaming',
                    value: 'Gaming',
                },
                {
                    label: 'Government',
                    value: 'Government',
                },
                {
                    label: 'Hacking',
                    value: 'Hacking',
                },
                {
                    label: 'Healthcare',
                    value: 'Healthcare',
                },
                {
                    label: 'Hosting',
                    value: 'Hosting',
                },
                {
                    label: 'Manufacturing',
                    value: 'Manufacturing',
                },
                {
                    label: 'Music',
                    value: 'Music',
                },
                {
                    label: 'NSFW Entertainment',
                    value: 'NSFW Entertainment',
                },
                {
                    label: 'Retail',
                    value: 'Retail',
                },
                {
                    label: 'Shopping',
                    value: 'Shopping',
                },
                {
                    label: 'Technology',
                    value: 'Technology',
                },
                {
                    label: 'Virtual Private Network',
                    value: 'Virtual Private Network',
                },
            ]
        },
        {
            label: 'Accommodation and Food Service Activities',
            value: 'Accommodation and Food Service Activities',
            children: [
                {
                    label: 'Caravan & Camping Sites',
                    value: 'Caravan & Camping Sites',
                },
                {
                    label: 'Catering Services',
                    value: 'Catering Services',
                },
                {
                    label: 'Food-Service Contractors',
                    value: 'Food-Service Contractors',
                },
                {
                    label: 'Holiday Accommodation',
                    value: 'Holiday Accommodation',
                },
                {
                    label: 'Hotels',
                    value: 'Hotels',
                },
                {
                    label: 'Pubs, Bars & Nightclubs',
                    value: 'Pubs, Bars & Nightclubs',
                },
                {
                    label: 'Restaurants & Takeaway Food Operators',
                    value: 'Restaurants & Takeaway Food Operators',
                },
            ]
        },
        {
            label: 'Administrative and Support Service Activities',
            value: 'Administrative and Support Service Activities',
            children: [
                {
                    label: 'Air Transport Equipment Rental & Leasing',
                    value: 'Air Transport Equipment Rental & Leasing',
                },
                {
                    label: 'Call Centres',
                    value: 'Call Centres',
                },
                {
                    label: 'Car Rental & Leasing',
                    value: 'Car Rental & Leasing',
                },
                {
                    label: 'Cleaning Activities',
                    value: 'Cleaning Activities',
                },
                {
                    label: 'Combined Facilities Support Activities',
                    value: 'Combined Facilities Support Activities',
                },
                {
                    label: 'Construction Equipment Rental & Leasing',
                    value: 'Construction Equipment Rental & Leasing',
                },
                {
                    label: 'Convention & Trade Fair Services',
                    value: 'Convention & Trade Fair Services',
                },
                {
                    label: 'Debt Collection, Credit Bureaux & Rating Agencies',
                    value: 'Debt Collection, Credit Bureaux & Rating Agencies',
                },
                {
                    label: 'Document Preparation Services',
                    value: 'Document Preparation Services',
                },
                {
                    label: 'Employment Placement Agencies',
                    value: 'Employment Placement Agencies',
                },
                {
                    label: 'Human Resources Provision',
                    value: 'Human Resources Provision',
                },
                {
                    label: 'Intellectual Property Leasing',
                    value: 'Intellectual Property Leasing',
                },
                {
                    label: 'Landscaping Services',
                    value: 'Landscaping Services',
                },
                {
                    label: 'Office Equipment Rental & Leasing',
                    value: 'Office Equipment Rental & Leasing',
                },
                {
                    label: 'Other Reservation Services',
                    value: 'Other Reservation Services',
                },
                {
                    label: 'Packaging Services',
                    value: 'Packaging Services',
                },
                {
                    label: 'Private Security Services',
                    value: 'Private Security Services',
                },
                {
                    label: 'Security System Services',
                    value: 'Security System Services',
                },
                {
                    label: 'Temporary-Employment Placement Agencies',
                    value: 'Temporary-Employment Placement Agencies',
                },
                {
                    label: 'Travel Agencies',
                    value: 'Travel Agencies',
                },
                {
                    label: 'Truck Rental & Leasing',
                    value: 'Truck Rental & Leasing',
                },
            ]
        },
        {
            label: 'Construction',
            value: 'Construction',
            children: [
                {
                    label: 'Bridge & Tunnel Construction',
                    value: 'Bridge & Tunnel Construction',
                },
                {
                    label: 'Building Completion & Finishing Activities',
                    value: 'Building Completion & Finishing Activities',
                },
                {
                    label: 'Building Project Development',
                    value: 'Building Project Development',
                },
                {
                    label: 'Civil Engineering Project Construction',
                    value: 'Civil Engineering Project Construction',
                },
                {
                    label: 'Dam, Harbour & Other Water Project Construction',
                    value: 'Dam, Harbour & Other Water Project Construction',
                },
                {
                    label: 'Demolition & site preparation',
                    value: 'Demolition & site preparation',
                },
                {
                    label: 'Electricians',
                    value: 'Electricians',
                },
                {
                    label: 'Electricity & Telecommunications Infrastructure Construction',
                    value: 'Electricity & Telecommunications Infrastructure Construction',
                },
                {
                    label: 'Other Construction Installation',
                    value: 'Other Construction Installation',
                },
                {
                    label: 'Other Specialised Construction Services',
                    value: 'Other Specialised Construction Services',
                },
                {
                    label: 'Plumbing, Heating & Air Conditioning Installation',
                    value: 'Plumbing, Heating & Air Conditioning Installation',
                },
                {
                    label: 'Railway & Underground Railway Construction',
                    value: 'Railway & Underground Railway Construction',
                },
                {
                    label: 'Residential & Nonresidential Building Construction',
                    value: 'Residential & Nonresidential Building Construction',
                },
                {
                    label: 'Road & Motorway Construction',
                    value: 'Road & Motorway Construction',
                },
                {
                    label: 'Roofing Activities',
                    value: 'Roofing Activities',
                },
                {
                    label: 'Test Drilling & Boring',
                    value: 'Test Drilling & Boring',
                },
            ]
        },
        {
            label: 'Electricity, Gas, Steam and Air Conditioning Supply',
            value: 'Electricity, Gas, Steam and Air Conditioning Supply',
            children: [
                {
                    label: 'Electric Power',
                    value: 'Electric Power',
                },
                {
                    label: 'Steam & Air Conditioning Supply',
                    value: 'Steam & Air Conditioning Supply',
                },
            ]
        },
        {
            label: 'Information and Communication',
            value: 'Information and Communication',
            children: [
                {
                    label: 'Book & Periodical Publishing',
                    value: 'Book & Periodical Publishing',
                },
                {
                    label: 'Cinemas',
                    value: 'Cinemas',
                },
                {
                    label: 'Computer Consultants',
                    value: 'Computer Consultants',
                },
                {
                    label: 'Computer Facilities Management',
                    value: 'Computer Facilities Management',
                },
                {
                    label: 'Data Processing & Hosting Services',
                    value: 'Data Processing & Hosting Services',
                },
                {
                    label: 'Film, Video & TV Programme Distribution',
                    value: 'Film, Video & TV Programme Distribution',
                },
                {
                    label: 'Film, Video & TV Programme Post-Production',
                    value: 'Film, Video & TV Programme Post-Production',
                },
                {
                    label: 'Film, Video & TV Programme Production',
                    value: 'Film, Video & TV Programme Production',
                },
                {
                    label: 'News Agencies',
                    value: 'News Agencies',
                },
                {
                    label: 'Radio Broadcasting',
                    value: 'Radio Broadcasting',
                },
                {
                    label: 'Satellite Telecommunications Activities',
                    value: 'Satellite Telecommunications Activities',
                },
                {
                    label: 'Software Development',
                    value: 'Software Development',
                },
                {
                    label: 'Sound Recording & Music Publishing',
                    value: 'Sound Recording & Music Publishing',
                },
                {
                    label: 'Telecommunications Resellers',
                    value: 'Telecommunications Resellers',
                },
                {
                    label: 'TV Programming & Broadcasting',
                    value: 'TV Programming & Broadcasting',
                },
                {
                    label: 'Wired Telecommunications Carriers',
                    value: 'Wired Telecommunications Carriers',
                },
                {
                    label: 'Wireless Telecommunications Carriers',
                    value: 'Wireless Telecommunications Carriers',
                },
            ]
        },
        {
            label: 'Manufacturing',
            value: 'Manufacturing',
            children: [
                {
                    label: 'Abrasive Product Manufacturing',
                    value: 'Abrasive Product Manufacturing',
                },
                {
                    label: 'Agricultural & Forestry Machinery Manufacturing',
                    value: 'Agricultural & Forestry Machinery Manufacturing',
                },
                {
                    label: 'Aircraft, Engine & Parts Manufacturing',
                    value: 'Aircraft, Engine & Parts Manufacturing',
                },
                {
                    label: 'Basic Pharmaceutical Product Manufacturing',
                    value: 'Basic Pharmaceutical Product Manufacturing',
                },
                {
                    label: 'Basic Precious & Other Nonferrous Metals Manufacturing',
                    value: 'Basic Precious & Other Nonferrous Metals Manufacturing',
                },
                {
                    label: 'Basic Steel Processing',
                    value: 'Basic Steel Processing',
                },
                {
                    label: 'Bearing & Gear Manufacturing',
                    value: 'Bearing & Gear Manufacturing',
                },
                {
                    label: 'Bicycle Manufacturing',
                    value: 'Bicycle Manufacturing',
                },
                {
                    label: 'Bread & Bakery Goods Production',
                    value: 'Bread & Bakery Goods Production',
                },
                {
                    label: 'Breweries',
                    value: 'Breweries',
                },
                {
                    label: 'Brick & Tile Manufacturing',
                    value: 'Brick & Tile Manufacturing',
                },
                {
                    label: 'Building Plaster Product Manufacturing',
                    value: 'Building Plaster Product Manufacturing',
                },
                {
                    label: 'Butter & Cheese Production',
                    value: 'Butter & Cheese Production',
                },
                {
                    label: 'Cardboard Packaging & Paper Bag Manufacturing',
                    value: 'Cardboard Packaging & Paper Bag Manufacturing',
                },
                {
                    label: 'Carpet & Rug Manufacturing',
                    value: 'Carpet & Rug Manufacturing',
                },
                {
                    label: 'Ceramic Household & Ornamental Article Manufacturing',
                    value: 'Ceramic Household & Ornamental Article Manufacturing',
                },
                {
                    label: 'Ceramic Sanitary Fixtures Manufacturing',
                    value: 'Ceramic Sanitary Fixtures Manufacturing',
                },
                {
                    label: 'Chemical Product Manufacturing',
                    value: 'Chemical Product Manufacturing',
                },
                {
                    label: 'Chocolate & Confectionery Production',
                    value: 'Chocolate & Confectionery Production',
                },
                {
                    label: 'Cider Production',
                    value: 'Cider Production',
                },
                {
                    label: 'Clothing Manufacturing',
                    value: 'Clothing Manufacturing',
                },
                {
                    label: 'Communication Equipment Manufacturing',
                    value: 'Communication Equipment Manufacturing',
                },
                {
                    label: 'Computer & Peripheral Equipment Manufacturing',
                    value: 'Computer & Peripheral Equipment Manufacturing',
                },
                {
                    label: 'Concrete & Construction Product Manufacturing',
                    value: 'Concrete & Construction Product Manufacturing',
                },
                {
                    label: 'Condiments & Seasoning Manufacturing',
                    value: 'Condiments & Seasoning Manufacturing',
                },
                {
                    label: 'Consumer Electronics Manufacturing',
                    value: 'Consumer Electronics Manufacturing',
                },
                {
                    label: 'Copper Production',
                    value: 'Copper Production',
                },
                {
                    label: 'Cutlery Manufacturing',
                    value: 'Cutlery Manufacturing',
                },
                {
                    label: 'Dye & Pigment Manufacturing',
                    value: 'Dye & Pigment Manufacturing',
                },
                {
                    label: 'Electric Appliance Manufacturing',
                    value: 'Electric Appliance Manufacturing',
                },
                {
                    label: 'Electric Lighting Equipment Manufacturing',
                    value: 'Electric Lighting Equipment Manufacturing',
                },
                {
                    label: 'Electric Motor, Generator & Transformer Manufacturing',
                    value: 'Electric Motor, Generator & Transformer Manufacturing',
                },
                {
                    label: 'Electricity Distribution & Control Apparatus Manufacturing',
                    value: 'Electricity Distribution & Control Apparatus Manufacturing',
                },
                {
                    label: 'Electromedical & Imaging Equipment Manufacturing',
                    value: 'Electromedical & Imaging Equipment Manufacturing',
                },
                {
                    label: 'Electronic Component Manufacturing',
                    value: 'Electronic Component Manufacturing',
                },
                {
                    label: 'Engine & Turbine Manufacturing',
                    value: 'Engine & Turbine Manufacturing',
                },
                {
                    label: 'Essential Oil Manufacturing',
                    value: 'Essential Oil Manufacturing',
                },
                {
                    label: 'Explosives Manufacturing',
                    value: 'Explosives Manufacturing',
                },
                {
                    label: 'Fabricated Metal Product Repair & Maintenance',
                    value: 'Fabricated Metal Product Repair & Maintenance',
                },
                {
                    label: 'Fertiliser & Nitrogen Compound Manufacturing',
                    value: 'Fertiliser & Nitrogen Compound Manufacturing',
                },
                {
                    label: 'Fibre-Optic Cable Manufacturing',
                    value: 'Fibre-Optic Cable Manufacturing',
                },
                {
                    label: 'Flat Glass Manufacturing',
                    value: 'Flat Glass Manufacturing',
                },
                {
                    label: 'Fluid Power Equipment Manufacturing',
                    value: 'Fluid Power Equipment Manufacturing',
                },
                {
                    label: 'Food & Beverage Processing Machinery Manufacturing',
                    value: 'Food & Beverage Processing Machinery Manufacturing',
                },
                {
                    label: 'Footwear Manufacturing',
                    value: 'Footwear Manufacturing',
                },
                {
                    label: 'Fruit & Vegetable Processing',
                    value: 'Fruit & Vegetable Processing',
                },
                {
                    label: 'General Purpose Machinery Manufacturing',
                    value: 'General Purpose Machinery Manufacturing',
                },
                {
                    label: 'Glass & Glass Product Manufacturing',
                    value: 'Glass & Glass Product Manufacturing',
                },
                {
                    label: 'Glue & Adhesives Manufacturing',
                    value: 'Glue & Adhesives Manufacturing',
                },
                {
                    label: 'Homogenised Food Preparations & Dietetic Food Manufacturing',
                    value: 'Homogenised Food Preparations & Dietetic Food Manufacturing',
                },
                {
                    label: 'Household Textile & Soft Furnishing Manufacturing',
                    value: 'Household Textile & Soft Furnishing Manufacturing',
                },
                {
                    label: 'Industrial Machinery Installation',
                    value: 'Industrial Machinery Installation',
                },
                {
                    label: 'Inorganic Basic Chemical Manufacturing',
                    value: 'Inorganic Basic Chemical Manufacturing',
                },
                {
                    label: 'Iron & Steel Manufacturing',
                    value: 'Iron & Steel Manufacturing',
                },
                {
                    label: 'Iron Casting',
                    value: 'Iron Casting',
                },
                {
                    label: 'Jewellery Manufacturing',
                    value: 'Jewellery Manufacturing',
                },
                {
                    label: 'Juice Production',
                    value: 'Juice Production',
                },
                {
                    label: 'Kitchen Furniture Manufacturing',
                    value: 'Kitchen Furniture Manufacturing',
                },
                {
                    label: 'Lead, Zinc & Tin Production',
                    value: 'Lead, Zinc & Tin Production',
                },
                {
                    label: 'Leather & Leather Goods Manufacturing',
                    value: 'Leather & Leather Goods Manufacturing',
                },
                {
                    label: 'Lifting & Handling Equipment Manufacturing',
                    value: 'Lifting & Handling Equipment Manufacturing',
                },
                {
                    label: 'Light Metal Casting',
                    value: 'Light Metal Casting',
                },
                {
                    label: 'Lime & Plaster Manufacturing',
                    value: 'Lime & Plaster Manufacturing',
                },
                {
                    label: 'Loaded Electronic Board Manufacturing',
                    value: 'Loaded Electronic Board Manufacturing',
                },
                {
                    label: 'Lock & Hinge Manufacturing',
                    value: 'Lock & Hinge Manufacturing',
                },
                {
                    label: 'Machining',
                    value: 'Machining',
                },
                {
                    label: 'Malt Manufacturing',
                    value: 'Malt Manufacturing',
                },
                {
                    label: 'Measuring, Testing & Navigational Equipment Manufacturing',
                    value: 'Measuring, Testing & Navigational Equipment Manufacturing',
                },
                {
                    label: 'Meat Processing',
                    value: 'Meat Processing',
                },
                {
                    label: 'Meat Product Manufacturing',
                    value: 'Meat Product Manufacturing',
                },
                {
                    label: 'Medical & Dental Instrument Manufacturing',
                    value: 'Medical & Dental Instrument Manufacturing',
                },
                {
                    label: 'Metal & Metal Powder Manufacturing',
                    value: 'Metal & Metal Powder Manufacturing',
                },
                {
                    label: 'Metal Door & Window Manufacturing',
                    value: 'Metal Door & Window Manufacturing',
                },
                {
                    label: 'Metal Forming Machinery Manufacturing',
                    value: 'Metal Forming Machinery Manufacturing',
                },
                {
                    label: 'Military Fighting Vehicle Manufacturing',
                    value: 'Military Fighting Vehicle Manufacturing',
                },
                {
                    label: 'Mining, Quarrying & Construction Machinery Manufacturing',
                    value: 'Mining, Quarrying & Construction Machinery Manufacturing',
                },
                {
                    label: 'Motor Vehicle Manufacturing',
                    value: 'Motor Vehicle Manufacturing',
                },
                {
                    label: 'Motor Vehicle Parts & Accessories Manufacturing',
                    value: 'Motor Vehicle Parts & Accessories Manufacturing',
                },
                {
                    label: 'Motorcycle Manufacturing',
                    value: 'Motorcycle Manufacturing',
                },
                {
                    label: 'Non-Domestic Cooling & Ventilation Equipment Manufacturing',
                    value: 'Non-Domestic Cooling & Ventilation Equipment Manufacturing',
                },
                {
                    label: 'Non-Metallic Mineral Product Manufacturing',
                    value: 'Non-Metallic Mineral Product Manufacturing',
                },
                {
                    label: 'Office & Shop Furniture Manufacturing',
                    value: 'Office & Shop Furniture Manufacturing',
                },
                {
                    label: 'Optical Instrument & Photographic Equipment Manufacturing',
                    value: 'Optical Instrument & Photographic Equipment Manufacturing',
                },
                {
                    label: 'Organic Basic Chemical Manufacturing',
                    value: 'Organic Basic Chemical Manufacturing',
                },
                {
                    label: 'Other Electrical Equipment Manufacturing',
                    value: 'Other Electrical Equipment Manufacturing',
                },
                {
                    label: 'Other Electronic Wire & Cable Manufacturing',
                    value: 'Other Electronic Wire & Cable Manufacturing',
                },
                {
                    label: 'Other Fabricated Metal Product Manufacturing',
                    value: 'Other Fabricated Metal Product Manufacturing',
                },
                {
                    label: 'Other Furniture Manufacturing',
                    value: 'Other Furniture Manufacturing',
                },
                {
                    label: 'Other Plastic Product Manufacturing',
                    value: 'Other Plastic Product Manufacturing',
                },
                {
                    label: 'Oven, Furnace & Furnace Burners Manufacturing',
                    value: 'Oven, Furnace & Furnace Burners Manufacturing',
                },
                {
                    label: 'Paint, Coatings & Printing Ink Manufacturing',
                    value: 'Paint, Coatings & Printing Ink Manufacturing',
                },
                {
                    label: 'Paper & Paperboard Manufacturing',
                    value: 'Paper & Paperboard Manufacturing',
                },
                {
                    label: 'Paper & Paperboard Production Machinery Manufacturing',
                    value: 'Paper & Paperboard Production Machinery Manufacturing',
                },
                {
                    label: 'Paper Stationery Manufacturing',
                    value: 'Paper Stationery Manufacturing',
                },
                {
                    label: 'Perfume & Cosmetics Manufacturing',
                    value: 'Perfume & Cosmetics Manufacturing',
                },
                {
                    label: 'Pesticide & Other Agrochemical Manufacturing',
                    value: 'Pesticide & Other Agrochemical Manufacturing',
                },
                {
                    label: 'Petroleum Refining',
                    value: 'Petroleum Refining',
                },
                {
                    label: 'Plastic Construction Supplies Manufacturing',
                    value: 'Plastic Construction Supplies Manufacturing',
                },
                {
                    label: 'Plastic Packing Goods Manufacturing',
                    value: 'Plastic Packing Goods Manufacturing',
                },
                {
                    label: 'Plastic Plates, Sheets, Tubes & Profiles Manufacturing',
                    value: 'Plastic Plates, Sheets, Tubes & Profiles Manufacturing',
                },
                {
                    label: 'Plastics & Rubber Machinery Manufacturing',
                    value: 'Plastics & Rubber Machinery Manufacturing',
                },
                {
                    label: 'Potato Crisps & Snacks Production',
                    value: 'Potato Crisps & Snacks Production',
                },
                {
                    label: 'Poultry Meat Processing',
                    value: 'Poultry Meat Processing',
                },
                {
                    label: 'Pre-Press & Pre-Media Services',
                    value: 'Pre-Press & Pre-Media Services',
                },
                {
                    label: 'Prepared Animal Feed Manufacturing',
                    value: 'Prepared Animal Feed Manufacturing',
                },
                {
                    label: 'Prepared Meal Manufacturing',
                    value: 'Prepared Meal Manufacturing',
                },
                {
                    label: 'Primary Form Plastics Manufacturing',
                    value: 'Primary Form Plastics Manufacturing',
                },
                {
                    label: 'Printing',
                    value: 'Printing',
                },
                {
                    label: 'Pump & Compressor Manufacturing',
                    value: 'Pump & Compressor Manufacturing',
                },
                {
                    label: 'Radiator & Boiler Manufacturing',
                    value: 'Radiator & Boiler Manufacturing',
                },
                {
                    label: 'Railway Equipment Manufacturing',
                    value: 'Railway Equipment Manufacturing',
                },
                {
                    label: 'Recreational Boat & Yacht Building',
                    value: 'Recreational Boat & Yacht Building',
                },
                {
                    label: 'Rubber Product Manufacturing',
                    value: 'Rubber Product Manufacturing',
                },
                {
                    label: 'Sanitary Product Manufacturing',
                    value: 'Sanitary Product Manufacturing',
                },
                {
                    label: 'Sawmilling & Wood Planing',
                    value: 'Sawmilling & Wood Planing',
                },
                {
                    label: 'Seafood Processing',
                    value: 'Seafood Processing',
                },
                {
                    label: 'Shipbuilding',
                    value: 'Shipbuilding',
                },
                {
                    label: 'Soap & Detergent Manufacturing',
                    value: 'Soap & Detergent Manufacturing',
                },
                {
                    label: 'Soft Drink Production',
                    value: 'Soft Drink Production',
                },
                {
                    label: 'Spirit Production',
                    value: 'Spirit Production',
                },
                {
                    label: 'Sporting Goods Manufacturing',
                    value: 'Sporting Goods Manufacturing',
                },
                {
                    label: 'Steel Casting',
                    value: 'Steel Casting',
                },
                {
                    label: 'Steel Tube, Pipe & Related Fittings Manufacturing',
                    value: 'Steel Tube, Pipe & Related Fittings Manufacturing',
                },
                {
                    label: 'Stone Cutting, Shaping & Finishing',
                    value: 'Stone Cutting, Shaping & Finishing',
                },
                {
                    label: 'Structural Metal Product Manufacturing',
                    value: 'Structural Metal Product Manufacturing',
                },
                {
                    label: 'Sugar Production',
                    value: 'Sugar Production',
                },
                {
                    label: 'Tap & Valve Manufacturing',
                    value: 'Tap & Valve Manufacturing',
                },
                {
                    label: 'Tea & Coffee Processing',
                    value: 'Tea & Coffee Processing',
                },
                {
                    label: 'Technical Textile Manufacturing',
                    value: 'Technical Textile Manufacturing',
                },
                {
                    label: 'Textile Finishing',
                    value: 'Textile Finishing',
                },
                {
                    label: 'Textile Weaving',
                    value: 'Textile Weaving',
                },
                {
                    label: 'Tobacco Product Manufacturing',
                    value: 'Tobacco Product Manufacturing',
                },
                {
                    label: 'Tool Manufacturing',
                    value: 'Tool Manufacturing',
                },
                {
                    label: 'Treatment & Coating of Metals',
                    value: 'Treatment & Coating of Metals',
                },
                {
                    label: 'Tyre Manufacturing',
                    value: 'Tyre Manufacturing',
                },
                {
                    label: 'Weapon & Ammunition Manufacturing',
                    value: 'Weapon & Ammunition Manufacturing',
                },
                {
                    label: 'Wine Production',
                    value: 'Wine Production',
                },
                {
                    label: 'Wiring Device Manufacturing',
                    value: 'Wiring Device Manufacturing',
                },
                {
                    label: 'Wood, Cork & Straw Product & Plaiting Material Manufacturing',
                    value: 'Wood, Cork & Straw Product & Plaiting Material Manufacturing',
                },
                {
                    label: 'Wooden Container Manufacturing',
                    value: 'Wooden Container Manufacturing',
                },
            ]
        },
        {
            label: 'Mining and Quarrying',
            value: 'Mining and Quarrying',
            children: [
                {
                    label: 'Crude Petroleum & Natural Gas Extraction',
                    value: 'Crude Petroleum & Natural Gas Extraction',
                },
                {
                    label: 'Gravel, Sand & Clay Extraction',
                    value: 'Gravel, Sand & Clay Extraction',
                },
                {
                    label: 'Petroleum & Natural Gas Support Activities',
                    value: 'Petroleum & Natural Gas Support Activities',
                },
                {
                    label: 'Salt Extraction',
                    value: 'Salt Extraction',
                },
                {
                    label: 'Stone Quarrying',
                    value: 'Stone Quarrying',
                },
            ]
        },
        {
            label: 'Professional, Scientific and Technical Activities',
            value: 'Professional, Scientific and Technical Activities',
            children: [
                {
                    label: 'Accounting & Auditing',
                    value: 'Accounting & Auditing',
                },
                {
                    label: 'Advertising Agencies',
                    value: 'Advertising Agencies',
                },
                {
                    label: 'Architectural Activities',
                    value: 'Architectural Activities',
                },
                {
                    label: 'Business Consultants',
                    value: 'Business Consultants',
                },
                {
                    label: 'Consultant Engineering Services',
                    value: 'Consultant Engineering Services',
                },
                {
                    label: 'Legal Services',
                    value: 'Legal Services',
                },
                {
                    label: 'Management Consultants',
                    value: 'Management Consultants',
                },
                {
                    label: 'Market Research & Public Opinion Polling',
                    value: 'Market Research & Public Opinion Polling',
                },
                {
                    label: 'Media Representation',
                    value: 'Media Representation',
                },
                {
                    label: 'Photographic Activities',
                    value: 'Photographic Activities',
                },
                {
                    label: 'Research & Development Activities',
                    value: 'Research & Development Activities',
                },
                {
                    label: 'Specialised Design Activities',
                    value: 'Specialised Design Activities',
                },
                {
                    label: 'Technical Testing & Analysis',
                    value: 'Technical Testing & Analysis',
                },
                {
                    label: 'Veterinary Services',
                    value: 'Veterinary Services',
                },
            ]
        },
        {
            label: 'Real Estate Activities',
            value: 'Real Estate Activities',
            children: [
                {
                    label: 'Buying & Selling Owned Real Estate',
                    value: 'Buying & Selling Owned Real Estate',
                },
                {
                    label: 'Real Estate Agencies',
                    value: 'Real Estate Agencies',
                },
                {
                    label: 'Real Estate Management',
                    value: 'Real Estate Management',
                },
                {
                    label: 'Renting & Operating Owned or Leased Real Estate',
                    value: 'Renting & Operating Owned or Leased Real Estate',
                },
            ]
        },
        {
            label: 'Transportation and Storage',
            value: 'Transportation and Storage',
            children: [
                {
                    label: 'Airports',
                    value: 'Airports',
                },
                {
                    label: 'Cargo Handling Activities',
                    value: 'Cargo Handling Activities',
                },
                {
                    label: 'Coach & Bus Transport',
                    value: 'Coach & Bus Transport',
                },
                {
                    label: 'Freight Forwarding & Customs Agents',
                    value: 'Freight Forwarding & Customs Agents',
                },
                {
                    label: 'Freight Rail Transport',
                    value: 'Freight Rail Transport',
                },
                {
                    label: 'Freight Road Transport',
                    value: 'Freight Road Transport',
                },
                {
                    label: 'Inland Freight Water Transport',
                    value: 'Inland Freight Water Transport',
                },
                {
                    label: 'Intercity Passenger Rail Transport',
                    value: 'Intercity Passenger Rail Transport',
                },
                {
                    label: 'Passenger Air Transport Services',
                    value: 'Passenger Air Transport Services',
                },
                {
                    label: 'Pipeline Transport',
                    value: 'Pipeline Transport',
                },
                {
                    label: 'Postal & Courier Activities',
                    value: 'Postal & Courier Activities',
                },
                {
                    label: 'Public Transportation',
                    value: 'Public Transportation',
                },
                {
                    label: 'Railways & Bus Station Operations',
                    value: 'Railways & Bus Station Operations',
                },
                {
                    label: 'Removal Services',
                    value: 'Removal Services',
                },
                {
                    label: 'Sea & Coastal Passenger Water Transport',
                    value: 'Sea & Coastal Passenger Water Transport',
                },
                {
                    label: 'Taxi Operation',
                    value: 'Taxi Operation',
                },
                {
                    label: 'Warehousing & Storage',
                    value: 'Warehousing & Storage',
                },
            ]
        },
        {
            label: 'Water Supply; Sewerage, Waste Management and Remediation Activities',
            value: 'Water Supply; Sewerage, Waste Management and Remediation Activities',
            children: [
                {
                    label: 'Hazardous Waste Treatment & Disposal',
                    value: 'Hazardous Waste Treatment & Disposal',
                },
                {
                    label: 'Non-Hazardous Waste Treatment & Disposal',
                    value: 'Non-Hazardous Waste Treatment & Disposal',
                },
                {
                    label: 'Recycling & Scrap Management',
                    value: 'Recycling & Scrap Management',
                },
                {
                    label: 'Sewerage',
                    value: 'Sewerage',
                },
                {
                    label: 'Waste Collection Activities',
                    value: 'Waste Collection Activities',
                },
                {
                    label: 'Waste Management Services',
                    value: 'Waste Management Services',
                },
                {
                    label: 'Water Collection, Treatment & Supply',
                    value: 'Water Collection, Treatment & Supply',
                },
            ]
        },
        {
            label: 'Wholesale and Retail Trade',
            value: 'Wholesale and Retail Trade',
            children: [
                {
                    label: 'Agent, Broker and Commission Merchant Wholesaling Activities',
                    value: 'Agent, Broker and Commission Merchant Wholesaling Activities',
                },
                {
                    label: 'Agricultural Machinery & Equipment Wholesaling',
                    value: 'Agricultural Machinery & Equipment Wholesaling',
                },
                {
                    label: 'Agricultural Products Wholesaling',
                    value: 'Agricultural Products Wholesaling',
                },
                {
                    label: 'Automotive Fuel Retailing',
                    value: 'Automotive Fuel Retailing',
                },
                {
                    label: 'Bakery Product Retailers',
                    value: 'Bakery Product Retailers',
                },
                {
                    label: 'Beverage Wholesaling',
                    value: 'Beverage Wholesaling',
                },
                {
                    label: 'Car Retailing',
                    value: 'Car Retailing',
                },
                {
                    label: 'Carpet, Rug & Curtain Retailers',
                    value: 'Carpet, Rug & Curtain Retailers',
                },
                {
                    label: 'Chemical Product Wholesaling',
                    value: 'Chemical Product Wholesaling',
                },
                {
                    label: 'Chemists & Pharmacies',
                    value: 'Chemists & Pharmacies',
                },
                {
                    label: 'China, Glassware & Related Cleaning Materials Wholesaling',
                    value: 'China, Glassware & Related Cleaning Materials Wholesaling',
                },
                {
                    label: 'Clothing & Footwear Wholesaling',
                    value: 'Clothing & Footwear Wholesaling',
                },
                {
                    label: 'Clothing Retailing',
                    value: 'Clothing Retailing',
                },
                {
                    label: 'Computer & Peripheral Equipment Wholesaling',
                    value: 'Computer & Peripheral Equipment Wholesaling',
                },
                {
                    label: 'Construction Supplies Wholesaling',
                    value: 'Construction Supplies Wholesaling',
                },
                {
                    label: 'Cosmetics & Toiletries Retailers',
                    value: 'Cosmetics & Toiletries Retailers',
                },
                {
                    label: 'Cultural & Recreation Goods Retailing',
                    value: 'Cultural & Recreation Goods Retailing',
                },
                {
                    label: 'Department Stores',
                    value: 'Department Stores',
                },
                {
                    label: 'E-Commerce & Online Auctions',
                    value: 'E-Commerce & Online Auctions',
                },
                {
                    label: 'Electrical Appliance Wholesaling',
                    value: 'Electrical Appliance Wholesaling',
                },
                {
                    label: 'Electrical Household Appliance Retailers',
                    value: 'Electrical Household Appliance Retailers',
                },
                {
                    label: 'Footwear & Leather Goods Retailers',
                    value: 'Footwear & Leather Goods Retailers',
                },
                {
                    label: 'Fruit & Vegetable Retailers',
                    value: 'Fruit & Vegetable Retailers',
                },
                {
                    label: 'Fruit & Vegetable Wholesaling',
                    value: 'Fruit & Vegetable Wholesaling',
                },
                {
                    label: 'Fuel Wholesaling',
                    value: 'Fuel Wholesaling',
                },
                {
                    label: 'Furniture, Carpet & Lighting Wholesaling',
                    value: 'Furniture, Carpet & Lighting Wholesaling',
                },
                {
                    label: 'Furniture, Lighting & Homeware Retailers',
                    value: 'Furniture, Lighting & Homeware Retailers',
                },
                {
                    label: 'Garden Centres & Pet Shops',
                    value: 'Garden Centres & Pet Shops',
                },
                {
                    label: 'Grocery Markets',
                    value: 'Grocery Markets',
                },
                {
                    label: 'Grocery Wholesaling',
                    value: 'Grocery Wholesaling',
                },
                {
                    label: 'Hardware & Home Improvement Stores',
                    value: 'Hardware & Home Improvement Stores',
                },
                {
                    label: 'Hardware, Plumbing & Heating Equipment Wholesaling',
                    value: 'Hardware, Plumbing & Heating Equipment Wholesaling',
                },
                {
                    label: 'Jewellery & Watch Stores',
                    value: 'Jewellery & Watch Stores',
                },
                {
                    label: 'Machine Tool Wholesaling',
                    value: 'Machine Tool Wholesaling',
                },
                {
                    label: 'Meat & Meat Product Retailers',
                    value: 'Meat & Meat Product Retailers',
                },
                {
                    label: 'Medical & Orthopaedic Supplies Retailers',
                    value: 'Medical & Orthopaedic Supplies Retailers',
                },
                {
                    label: 'Metal & Metal Ore Wholesaling',
                    value: 'Metal & Metal Ore Wholesaling',
                },
                {
                    label: 'Mining, Construction & Civil Engineering Machinery Wholesaling',
                    value: 'Mining, Construction & Civil Engineering Machinery Wholesaling',
                },
                {
                    label: 'Mobile Phone Retailing',
                    value: 'Mobile Phone Retailing',
                },
                {
                    label: 'Motor Vehicle Parts & Accessories Retailers',
                    value: 'Motor Vehicle Parts & Accessories Retailers',
                },
                {
                    label: 'Motor Vehicle Repair & Maintenance',
                    value: 'Motor Vehicle Repair & Maintenance',
                },
                {
                    label: 'Motorcycle Sales & Repair',
                    value: 'Motorcycle Sales & Repair',
                },
                {
                    label: 'Non-Specialised Grocery Wholesaling',
                    value: 'Non-Specialised Grocery Wholesaling',
                },
                {
                    label: 'Off-Licences',
                    value: 'Off-Licences',
                },
                {
                    label: 'Office Furniture Wholesaling',
                    value: 'Office Furniture Wholesaling',
                },
                {
                    label: 'Other Household Goods Wholesaling',
                    value: 'Other Household Goods Wholesaling',
                },
                {
                    label: 'Other Specialised Retailers',
                    value: 'Other Specialised Retailers',
                },
                {
                    label: 'Perfume & Cosmetics Wholesaling',
                    value: 'Perfume & Cosmetics Wholesaling',
                },
                {
                    label: 'Pharmaceutical Wholesaling',
                    value: 'Pharmaceutical Wholesaling',
                },
                {
                    label: 'Seafood Retailers',
                    value: 'Seafood Retailers',
                },
                {
                    label: 'Second-Hand Goods Stores',
                    value: 'Second-Hand Goods Stores',
                },
                {
                    label: 'Supermarkets & Grocery Stores',
                    value: 'Supermarkets & Grocery Stores',
                },
                {
                    label: 'Textile Retailing',
                    value: 'Textile Retailing',
                },
                {
                    label: 'Textile Wholesaling',
                    value: 'Textile Wholesaling',
                },
                {
                    label: 'Tobacconists',
                    value: 'Tobacconists',
                },
                {
                    label: 'Waste & Scrap Wholesaling',
                    value: 'Waste & Scrap Wholesaling',
                },
                {
                    label: 'Watch & Jewellery Wholesaling',
                    value: 'Watch & Jewellery Wholesaling',
                },
            ]
        },
    ];

    const onChangeIndustry = (value: string[][]) => {
        setIndustries(value);
    };

    const clearList = (index) => {
        switch(index) {
            case 0:
                setFileList([]);
                break;
            case 1:
                setFileList2([]);
                break;
            case 2:
                setFileList3([]);
                break;
            case 3:
                setFileList4([]);
                break;
            case 4:
                setFileList5([]);
                break;
        }
    }

    const clearAlgorithm = (index) => {
        let copy = algorithms;
        copy[index] = undefined;
        setAlgorithms(copy);
    }

    const updateAlgorithm = (index, new_algorithm) => {
        let copy = algorithms;
        copy[index] = new_algorithm;
        setAlgorithms(copy);
    }

    const doSubmit = () => {
        let error = false;
        if(dataBreachUploadList.length < 1) {
            message.error("Add at least one data breach file.");
            error = true;
        }
        if(fileList.length < 1) {
            message.error("Add at least one hashlist file.");
            error = true;
        }

        if(form.getFieldsValue()['database_name'] === undefined || form.getFieldsValue()['database_name'].length < 2) {
            message.error("Please specify the database name");
            error = true;
        }
        if(form.getFieldsValue()['domain'] === undefined || form.getFieldsValue()['domain'].length < 5) {
            message.error("Add a relevant domain name.");
            error = true;
        }
        if(industries.length < 1) {
            message.error("Select the relevant industries.");
            error = true;
        }
        if(
            (form.getFieldsValue()['breach_year'] === undefined || form.getFieldsValue()['breach_year'] === null) &&
            (form.getFieldsValue()['breach_month'] === undefined || form.getFieldsValue()['breach_month'] === null) &&
            (form.getFieldsValue()['breach_day'] === undefined || form.getFieldsValue()['breach_day'] === null)
        ) {
            message.error("Select a breach date. Be as accurate as possible.");
            error = true;
        }

        let date = new Date("0000-00-00");
        if(form.getFieldsValue()['breach_year'] !== undefined && form.getFieldsValue()['breach_year'] !== null) {
            date = new Date(form.getFieldsValue()['breach_year']['$y'] + "-01-01");
            if (date > new Date(Date())) {
                message.error("Breach can not be in the future.");
                error = true;
            }
        }

        if(form.getFieldsValue()['breach_month'] !== undefined && form.getFieldsValue()['breach_month'] !== null) {
            let month = (parseInt(form.getFieldsValue()['breach_month']['$M'])+1).toString();
            const prefix_zero = (month < 9) ? "0" : "";
            date = new Date(form.getFieldsValue()['breach_month']['$y'] + "-" + prefix_zero + month + "-01");
            if (date > new Date(Date())) {
                message.error("Breach can not be in the future.");
                error = true;
            }
        }

        if(form.getFieldsValue()['breach_day'] !== undefined && form.getFieldsValue()['breach_day'] !== null) {
            let month = (parseInt(form.getFieldsValue()['breach_day']['$M'])+1).toString();
            const prefix_zero = (month < 9) ? "0" : "";
            const prefix_zero_day = (form.getFieldsValue()['breach_day']['$D'] < 9) ? "0" : "";
            date = new Date(form.getFieldsValue()['breach_day']['$y'] + "-" + prefix_zero + month + "-" + prefix_zero_day + form.getFieldsValue()['breach_day']['$D']);
            if (date > new Date(Date())) {
                message.error("Breach can not be in the future.");
                error = true;
            }
        }

        if(algorithms[0] === undefined) {
            error = true;
            message.error("Must contain at least one hashlist.");
        }

        if(algorithms[0] !== undefined && fileList.length === 0) {
            error = true;
            message.error("Algorithm 1 is missing a file.");
        }

        if(algorithms[1] !== undefined && fileList2.length === 0) {
            error = true;
            message.error("Algorithm 2 is missing a file.");
        }

        if(algorithms[2] !== undefined && fileList3.length === 0) {
            error = true;
            message.error("Algorithm 3 is missing a file.");
        }

        if(algorithms[3] !== undefined && fileList4.length === 0) {
            error = true;
            message.error("Algorithm 4 is missing a file.");
        }

        if(algorithms[3] !== undefined && fileList5.length === 0) {
            error = true;
            message.error("Algorithm 5 is missing a file.");
        }
        if(error) return;

        // console.log(form.getFieldsValue());
        // console.log(dataBreachUploadList);
        // console.log(fileList);
        // console.log(fileList2);
        // console.log(fileList3);
        // console.log(fileList4);
        // console.log(fileList5);
        // console.log(uploading);
        // console.log(industries);
        // console.log(algorithms);

        const formData = new FormData();
        formData.append('database_name', form.getFieldsValue()['database_name']);
        formData.append('domain', form.getFieldsValue()['domain']);
        formData.append('breach_date', date.toISOString().split('T')[0]);
        if(form.getFieldsValue()['breach_date_source'] !== undefined && form.getFieldsValue()['breach_date_source'] !== null) {
            formData.append('breach_date_source', form.getFieldsValue()['breach_date_source']);
        }

        if(form.getFieldsValue()['breach_admission']) {
            formData.append('breach_admission', form.getFieldsValue()['breach_admission']);
        }

        industries.forEach((industry) => {
            formData.append('industries[]', industry);
        });

        dataBreachUploadList.forEach((file) => {
            formData.append('data_breach[]', file);
        });

        algorithms.forEach((algorithm) => {
            if(algorithm !== undefined && algorithm !== null) {
                formData.append('algorithms[]', algorithm);
            }
        });
        fileList.forEach((file) => {
            formData.append('algorithm1[]', file);
        });
        fileList2.forEach((file) => {
            formData.append('algorithm2[]', file);
        });
        fileList3.forEach((file) => {
            formData.append('algorithm3[]', file);
        });
        fileList4.forEach((file) => {
            formData.append('algorithm4[]', file);
        });
        fileList5.forEach((file) => {
            formData.append('algorithm5[]', file);
        });

        setUploading(true);
        // You can use any AJAX library you like
        fetch('https://leaksubmit.hashmob.net/api/leak', {
            method: 'POST',
            body: formData,
        }).then((response) => response.json()).then((data) => {
            if(data.status === "Success") {
                form.resetFields();
                setSuccessUploaded(true);
            } else {
                if (data.hasOwnProperty("error_message")) {
                    Object.values(data.error_message).map(function (item) {
                        message.error("Upload failed. " + item, 30);
                    });
                }
            }
        }).catch(function(error) {
            if (error.response) {
                if (error.response.data.hasOwnProperty("error_message")) {
                    Object.values(error.response.data.error_message).map(function (item) {
                        message.error("Upload failed. " + item, 30);
                    });
                } else {
                    message.error("Upload failed. " + error.response.data.message, 30);
                }
            }
        }).finally(() => {
            setUploading(false);
        });
    }

    return (
        <Content
            style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
            }}
        >
            <Row gutter={16} style={{
                justifyContent: 'center'
            }}>
                <Modal title="Successfully Uploaded" open={successUploaded} onOk={() => {setSuccessUploaded(false)}} onCancel={() => {setSuccessUploaded(false)}}>
                    <p>The submission has successfully uploaded. You can now close this page.</p>
                </Modal>
                <Card title={"Leak Submission Application"}>
                    Welcome to the HashMob leak submission application. Please fill in all the fields as best you can. Your request can take a while to be processed.<br/>
                    <br/>
                    <Form
                        form={form}
                        layout="vertical"
                        requiredMark={'optional'}
                    >
                        <Form.Item
                            label="Data Breach Name"
                            required
                            tooltip="Specify a name for the data breach."
                            name={"database_name"}
                        >
                            <Input name={'db_name'} placeholder="HashMob Enterprise Tld." />
                        </Form.Item>

                        <Form.Item
                            label="Website Domain (http/https optional)"
                            required
                            tooltip={{ title: 'Try to ensure the TLD is correct', icon: <InfoCircleOutlined /> }}
                            name={"domain"}
                        >
                            <Input placeholder="HashMob.net" />
                        </Form.Item>

                        <Form.Item
                            label="Breach Date (only one required)"
                            style={{ marginBottom: 0 }}
                            tooltip={{ title: 'Try to be as accurate as possible', icon: <InfoCircleOutlined /> }}
                        >
                            <Form.Item
                                tooltip={{ title: 'Try to ensure the TLD is correct', icon: <InfoCircleOutlined /> }}
                                style={{ display: 'inline-block', marginRight: '15px'}}
                                name={"breach_year"}
                            >
                                <DatePicker picker="year" />
                            </Form.Item>
                            <Form.Item
                                tooltip={{ title: 'Try to ensure the TLD is correct', icon: <InfoCircleOutlined /> }}
                                style={{ display: 'inline-block', marginRight: '15px'}}
                                name={"breach_month"}
                            >
                                <DatePicker picker="month" />
                            </Form.Item>
                            <Form.Item
                                tooltip={{ title: 'Try to ensure the TLD is correct', icon: <InfoCircleOutlined /> }}
                                style={{ display: 'inline-block' }}
                                name={"breach_day"}
                            >
                                <DatePicker picker="day" />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item
                            label="Source of Breach Date"
                            required
                            tooltip={{ title: 'For reference & accuracy', icon: <InfoCircleOutlined /> }}
                            name={"breach_date_source"}
                        >
                            <Input placeholder="https://en.wikipedia.org/wiki/2012_LinkedIn_hack" />
                        </Form.Item>

                        <Form.Item
                            label="Admission of Breach"
                            tooltip={{ title: 'For reference & accuracy', icon: <InfoCircleOutlined /> }}
                            name={"breach_admission"}
                            rules={[
                                    {
                                        required: false,
                                        whitespace: true,
                                        message: "Please specify a valid URL.",
                                        type: 'url'
                                    },
                                ]
                            }
                        >
                            <Input placeholder="https://blog.lastpass.com/2022/12/notice-of-recent-security-incident/" />
                        </Form.Item>

                        <Form.Item
                            label="Industry Sector(s)"
                            required
                            tooltip={{ title: 'Examples: Technology, Finance, Investment, Production, Porn, Energy, Infrastructure, Rail transport, Bank, Media, Aviation, Health', icon: <InfoCircleOutlined /> }}
                        >
                            <Cascader
                                style={{ width: '100%' }}
                                options={industry_sectors}
                                onChange={onChangeIndustry}
                                multiple
                                maxTagCount="responsive"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Full Databreach"
                            required
                            tooltip={{ title: 'We utilize this to recover additional fields used as salts or as backup in case of corruption', icon: <InfoCircleOutlined /> }}
                        >
                            <Upload {...breachUpload}>
                                <Button icon={<UploadOutlined />}>Attach File</Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            label="Hashlists"
                            tooltip={{ title: 'Include all identified algorithms. One algorithm per file, one hash per line. The entire file should have the same format of either hash, or hash:salt. Not mixed', icon: <InfoCircleOutlined /> }}
                        >
                            <small>
                                Parsed hashes from the databreach. One file per algorithm in the format of hash or hash:salt.<br/>
                                One hash per line. Do not submit multiple algorithms in one file and both deduplicate and sort all hashlists before uploading.<br/>
                                It should be identical to how you would download them from HashMob. Select "Unknown" if the algorithm is not known (yet).
                            </small>
                            <div style={{ height: "5px"}}/>
                            <Form.List name={"placeholder"}>
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                required={false}
                                                key={field.key}
                                                style={{marginBottom: "10px"}}
                                            >
                                                {fields.length >= 1 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => {
                                                            clearList(index);
                                                            clearAlgorithm(index);
                                                            remove(field.name);
                                                        }}
                                                        style={{marginRight: "10px"}}
                                                    />
                                                ) : null}
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the algorithm & Upload the file.",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: "40%", marginRight: "15px"}}
                                                        placeholder="Select a hashcat algorithm"
                                                        optionFilterProp="children"
                                                        onChange={(value) => updateAlgorithm(index, value)}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Select.Option value="-2">Unknown</Select.Option>
                                                        <Select.Option value="-1">Other</Select.Option>
                                                        <Select.Option value="0">0 - MD5</Select.Option>
                                                        <Select.Option value="10">10 - md5($pass.$salt)</Select.Option>
                                                        <Select.Option value="11">11 - Joomla &lt; 2.5.18</Select.Option>
                                                        <Select.Option value="12">12 - PostgreSQL</Select.Option>
                                                        <Select.Option value="20">20 - md5($salt.$pass)</Select.Option>
                                                        <Select.Option value="21">21 - osCommerce, xt:Commerce</Select.Option>
                                                        <Select.Option value="22">22 - Juniper NetScreen/SSG (ScreenOS)</Select.Option>
                                                        <Select.Option value="23">23 - Skype</Select.Option>
                                                        <Select.Option value="24">24 - SolarWinds Serv-U</Select.Option>
                                                        <Select.Option value="30">30 - md5(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="40">40 - md5($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="50">50 - HMAC-MD5 (key = $pass)</Select.Option>
                                                        <Select.Option value="60">60 - HMAC-MD5 (key = $salt)</Select.Option>
                                                        <Select.Option value="70">70 - md5(utf16le($pass))</Select.Option>
                                                        <Select.Option value="100">100 - SHA1</Select.Option>
                                                        <Select.Option value="101">101 - nsldap, SHA-1(Base64), Netscape LDAP SHA</Select.Option>
                                                        <Select.Option value="110">110 - sha1($pass.$salt)</Select.Option>
                                                        <Select.Option value="111">111 - nsldaps, SSHA-1(Base64), Netscape LDAP SSHA</Select.Option>
                                                        <Select.Option value="112">112 - Oracle S: Type (Oracle 11+)</Select.Option>
                                                        <Select.Option value="120">120 - sha1($salt.$pass)</Select.Option>
                                                        <Select.Option value="121">121 - SMF (Simple Machines Forum) > v1.1</Select.Option>
                                                        <Select.Option value="122">122 - macOS v10.4, macOS v10.5, macOS v10.6</Select.Option>
                                                        <Select.Option value="124">124 - Django (SHA-1)</Select.Option>
                                                        <Select.Option value="125">125 - ArubaOS</Select.Option>
                                                        <Select.Option value="130">130 - sha1(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="131">131 - MSSQL (2000)</Select.Option>
                                                        <Select.Option value="132">132 - MSSQL (2005)</Select.Option>
                                                        <Select.Option value="133">133 - PeopleSoft</Select.Option>
                                                        <Select.Option value="140">140 - sha1($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="141">141 - Episerver 6.x &lt; .NET 4</Select.Option>
                                                        <Select.Option value="150">150 - HMAC-SHA1 (key = $pass)</Select.Option>
                                                        <Select.Option value="160">160 - HMAC-SHA1 (key = $salt)</Select.Option>
                                                        <Select.Option value="170">170 - sha1(utf16le($pass))</Select.Option>
                                                        <Select.Option value="200">200 - MySQL323</Select.Option>
                                                        <Select.Option value="300">300 - MySQL4.1/MySQL5</Select.Option>
                                                        <Select.Option value="400">400 - phpass</Select.Option>
                                                        <Select.Option value="500">500 - md5crypt, MD5 (Unix), Cisco-IOS $1$ (MD5)</Select.Option>
                                                        <Select.Option value="501">501 - Juniper IVE</Select.Option>
                                                        <Select.Option value="600">600 - BLAKE2b-512</Select.Option>
                                                        <Select.Option value="610">610 - BLAKE2b-512($pass.$salt)</Select.Option>
                                                        <Select.Option value="620">620 - BLAKE2b-512($salt.$pass)</Select.Option>
                                                        <Select.Option value="900">900 - MD4</Select.Option>
                                                        <Select.Option value="1000">1000 - NTLM</Select.Option>
                                                        <Select.Option value="1001">1001 - Troy Hunt NTLM</Select.Option>
                                                        <Select.Option value="1100">1100 - Domain Cached Credentials (DCC), MS Cache</Select.Option>
                                                        <Select.Option value="1300">1300 - SHA2-224</Select.Option>
                                                        <Select.Option value="1400">1400 - SHA2-256</Select.Option>
                                                        <Select.Option value="1410">1410 - sha256($pass.$salt)</Select.Option>
                                                        <Select.Option value="1411">1411 - SSHA-256(Base64), LDAP SSHA256</Select.Option>
                                                        <Select.Option value="1420">1420 - sha256($salt.$pass)</Select.Option>
                                                        <Select.Option value="1421">1421 - hMailServer</Select.Option>
                                                        <Select.Option value="1430">1430 - sha256(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="1440">1440 - sha256($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="1441">1441 - Episerver 6.x >= .NET 4</Select.Option>
                                                        <Select.Option value="1450">1450 - HMAC-SHA256 (key = $pass)</Select.Option>
                                                        <Select.Option value="1460">1460 - HMAC-SHA256 (key = $salt)</Select.Option>
                                                        <Select.Option value="1470">1470 - sha256(utf16le($pass))</Select.Option>
                                                        <Select.Option value="1500">1500 - descrypt, DES (Unix), Traditional DES</Select.Option>
                                                        <Select.Option value="1600">1600 - Apache $apr1$ MD5, md5apr1, MD5 (APR)</Select.Option>
                                                        <Select.Option value="1700">1700 - SHA2-512</Select.Option>
                                                        <Select.Option value="1710">1710 - sha512($pass.$salt)</Select.Option>
                                                        <Select.Option value="1711">1711 - SSHA-512(Base64), LDAP SSHA512</Select.Option>
                                                        <Select.Option value="1720">1720 - sha512($salt.$pass)</Select.Option>
                                                        <Select.Option value="1722">1722 - macOS v10.7</Select.Option>
                                                        <Select.Option value="1730">1730 - sha512(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="1731">1731 - MSSQL (2012, 2014)</Select.Option>
                                                        <Select.Option value="1740">1740 - sha512($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="1750">1750 - HMAC-SHA512 (key = $pass)</Select.Option>
                                                        <Select.Option value="1760">1760 - HMAC-SHA512 (key = $salt)</Select.Option>
                                                        <Select.Option value="1770">1770 - sha512(utf16le($pass))</Select.Option>
                                                        <Select.Option value="1800">1800 - sha512crypt $6$, SHA512 (Unix)</Select.Option>
                                                        <Select.Option value="2100">2100 - Domain Cached Credentials 2 (DCC2), MS Cache 2</Select.Option>
                                                        <Select.Option value="2400">2400 - Cisco-PIX MD5</Select.Option>
                                                        <Select.Option value="2410">2410 - Cisco-ASA MD5</Select.Option>
                                                        <Select.Option value="2500">2500 - WPA-EAPOL-PBKDF2</Select.Option>
                                                        <Select.Option value="2501">2501 - WPA-EAPOL-PMK</Select.Option>
                                                        <Select.Option value="2600">2600 - md5(md5($pass))</Select.Option>
                                                        <Select.Option value="2611">2611 - vBulletin &lt; v3.8.5</Select.Option>
                                                        <Select.Option value="2612">2612 - PHPS</Select.Option>
                                                        <Select.Option value="2711">2711 - vBulletin >= v3.8.5</Select.Option>
                                                        <Select.Option value="2811">2811 - MyBB 1.2+, IPB2+ (Invision Power Board)</Select.Option>
                                                        <Select.Option value="3000">3000 - LM</Select.Option>
                                                        <Select.Option value="3100">3100 - Oracle H: Type (Oracle 7+)</Select.Option>
                                                        <Select.Option value="3200">3200 - bcrypt $2*$, Blowfish (Unix)</Select.Option>
                                                        <Select.Option value="3500">3500 - md5(md5(md5($pass)))</Select.Option>
                                                        <Select.Option value="3710">3710 - md5($salt.md5($pass))</Select.Option>
                                                        <Select.Option value="3711">3711 - MediaWiki B type</Select.Option>
                                                        <Select.Option value="3730">3730 - md5($salt1.strtoupper(md5($salt2.$pass)))</Select.Option>
                                                        <Select.Option value="3800">3800 - md5($salt.$pass.$salt)</Select.Option>
                                                        <Select.Option value="3910">3910 - md5(md5($pass).md5($salt))</Select.Option>
                                                        <Select.Option value="4010">4010 - md5($salt.md5($salt.$pass))</Select.Option>
                                                        <Select.Option value="4110">4110 - md5($salt.md5($pass.$salt))</Select.Option>
                                                        <Select.Option value="4300">4300 - md5(strtoupper(md5($pass)))</Select.Option>
                                                        <Select.Option value="4400">4400 - md5(sha1($pass))</Select.Option>
                                                        <Select.Option value="4410">4410 - md5(sha1($pass).$salt)</Select.Option>
                                                        <Select.Option value="4500">4500 - sha1(sha1($pass))</Select.Option>
                                                        <Select.Option value="4501">4501 - sha1(sha1($pass).0x0d) / SHA1SHA1HUMx01</Select.Option>
                                                        <Select.Option value="4510">4510 - sha1(sha1($pass).$salt)</Select.Option>
                                                        <Select.Option value="4520">4520 - sha1($salt.sha1($pass))</Select.Option>
                                                        <Select.Option value="4521">4521 - Redmine</Select.Option>
                                                        <Select.Option value="4522">4522 - PunBB</Select.Option>
                                                        <Select.Option value="4700">4700 - sha1(md5($pass))</Select.Option>
                                                        <Select.Option value="4710">4710 - sha1(md5($pass).$salt)</Select.Option>
                                                        <Select.Option value="4711">4711 - Huawei sha1(md5($pass).$salt)</Select.Option>
                                                        <Select.Option value="4800">4800 - iSCSI CHAP authentication, MD5(CHAP)</Select.Option>
                                                        <Select.Option value="4900">4900 - sha1($salt.$pass.$salt)</Select.Option>
                                                        <Select.Option value="5000">5000 - sha1(sha1($salt.$pass.$salt))</Select.Option>
                                                        <Select.Option value="5100">5100 - Half MD5</Select.Option>
                                                        <Select.Option value="5200">5200 - Password Safe v3</Select.Option>
                                                        <Select.Option value="5300">5300 - IKE-PSK MD5</Select.Option>
                                                        <Select.Option value="5400">5400 - IKE-PSK SHA1</Select.Option>
                                                        <Select.Option value="5500">5500 - NetNTLMv1 / NetNTLMv1+ESS</Select.Option>
                                                        <Select.Option value="5600">5600 - NetNTLMv2</Select.Option>
                                                        <Select.Option value="5700">5700 - Cisco-IOS type 4 (SHA256)</Select.Option>
                                                        <Select.Option value="5800">5800 - Samsung Android Password/PIN</Select.Option>
                                                        <Select.Option value="6000">6000 - RIPEMD-160</Select.Option>
                                                        <Select.Option value="6050">6050 - HMAC-RIPEMD160 (key = $pass)</Select.Option>
                                                        <Select.Option value="6060">6060 - HMAC-RIPEMD160 (key = $salt)</Select.Option>
                                                        <Select.Option value="6100">6100 - Whirlpool</Select.Option>
                                                        <Select.Option value="6211">6211 - TrueCrypt RIPEMD160 + XTS 512 bit (legacy)</Select.Option>
                                                        <Select.Option value="6212">6212 - TrueCrypt RIPEMD160 + XTS 1024 bit (legacy)</Select.Option>
                                                        <Select.Option value="6213">6213 - TrueCrypt RIPEMD160 + XTS 1536 bit (legacy)</Select.Option>
                                                        <Select.Option value="6221">6221 - TrueCrypt SHA512 + XTS 512 bit (legacy)</Select.Option>
                                                        <Select.Option value="6222">6222 - TrueCrypt SHA512 + XTS 1024 bit (legacy)</Select.Option>
                                                        <Select.Option value="6223">6223 - TrueCrypt SHA512 + XTS 1536 bit (legacy)</Select.Option>
                                                        <Select.Option value="6231">6231 - TrueCrypt Whirlpool + XTS 512 bit (legacy)</Select.Option>
                                                        <Select.Option value="6232">6232 - TrueCrypt Whirlpool + XTS 1024 bit (legacy)</Select.Option>
                                                        <Select.Option value="6233">6233 - TrueCrypt Whirlpool + XTS 1536 bit (legacy)</Select.Option>
                                                        <Select.Option value="6241">6241 - TrueCrypt RIPEMD160 + XTS 512 bit + boot-mode (legacy)</Select.Option>
                                                        <Select.Option value="6242">6242 - TrueCrypt RIPEMD160 + XTS 1024 bit + boot-mode (legacy)</Select.Option>
                                                        <Select.Option value="6243">6243 - TrueCrypt RIPEMD160 + XTS 1536 bit + boot-mode (legacy)</Select.Option>
                                                        <Select.Option value="6300">6300 - AIX smd5</Select.Option>
                                                        <Select.Option value="6400">6400 - AIX ssha256</Select.Option>
                                                        <Select.Option value="6500">6500 - AIX ssha512</Select.Option>
                                                        <Select.Option value="6600">6600 - 1Password, agilekeychain</Select.Option>
                                                        <Select.Option value="6700">6700 - AIX ssha1</Select.Option>
                                                        <Select.Option value="6800">6800 - LastPass + LastPass sniffed</Select.Option>
                                                        <Select.Option value="6900">6900 - GOST R 34.11-94</Select.Option>
                                                        <Select.Option value="7000">7000 - FortiGate (FortiOS)</Select.Option>
                                                        <Select.Option value="7100">7100 - macOS v10.8+ (PBKDF2-SHA512)</Select.Option>
                                                        <Select.Option value="7200">7200 - GRUB 2</Select.Option>
                                                        <Select.Option value="7300">7300 - IPMI2 RAKP HMAC-SHA1</Select.Option>
                                                        <Select.Option value="7350">7350 - IPMI2 RAKP HMAC-MD5</Select.Option>
                                                        <Select.Option value="7400">7400 - sha256crypt $5$, SHA256 (Unix)</Select.Option>
                                                        <Select.Option value="7401">7401 - MySQL $A$ (sha256crypt)</Select.Option>
                                                        <Select.Option value="7500">7500 - Kerberos 5, etype 23, AS-REQ Pre-Auth</Select.Option>
                                                        <Select.Option value="7700">7700 - SAP CODVN B (BCODE)</Select.Option>
                                                        <Select.Option value="7701">7701 - SAP CODVN B (BCODE) from RFC_READ_TABLE</Select.Option>
                                                        <Select.Option value="7800">7800 - SAP CODVN F/G (PASSCODE)</Select.Option>
                                                        <Select.Option value="7801">7801 - SAP CODVN F/G (PASSCODE) from RFC_READ_TABLE</Select.Option>
                                                        <Select.Option value="7900">7900 - Drupal7</Select.Option>
                                                        <Select.Option value="8000">8000 - Sybase ASE</Select.Option>
                                                        <Select.Option value="8100">8100 - Citrix NetScaler (SHA1)</Select.Option>
                                                        <Select.Option value="8200">8200 - 1Password, cloudkeychain</Select.Option>
                                                        <Select.Option value="8300">8300 - DNSSEC (NSEC3)</Select.Option>
                                                        <Select.Option value="8400">8400 - WBB3 (Woltlab Burning Board)</Select.Option>
                                                        <Select.Option value="8500">8500 - RACF</Select.Option>
                                                        <Select.Option value="8600">8600 - Lotus Notes/Domino 5</Select.Option>
                                                        <Select.Option value="8700">8700 - Lotus Notes/Domino 6</Select.Option>
                                                        <Select.Option value="8800">8800 - Android FDE &lt;= 4.3</Select.Option>
                                                        <Select.Option value="8900">8900 - scrypt</Select.Option>
                                                        <Select.Option value="9000">9000 - Password Safe v2</Select.Option>
                                                        <Select.Option value="9100">9100 - Lotus Notes/Domino 8</Select.Option>
                                                        <Select.Option value="9200">9200 - Cisco-IOS $8$ (PBKDF2-SHA256)</Select.Option>
                                                        <Select.Option value="9300">9300 - Cisco-IOS $9$ (scrypt)</Select.Option>
                                                        <Select.Option value="9400">9400 - MS Office 2007</Select.Option>
                                                        <Select.Option value="9500">9500 - MS Office 2010</Select.Option>
                                                        <Select.Option value="9600">9600 - MS Office 2013</Select.Option>
                                                        <Select.Option value="9700">9700 - MS Office &lt;= 2003 $0/$1, MD5 + RC4</Select.Option>
                                                        <Select.Option value="9710">9710 - MS Office &lt;= 2003 $0/$1, MD5 + RC4, collider #1</Select.Option>
                                                        <Select.Option value="9720">9720 - MS Office &lt;= 2003 $0/$1, MD5 + RC4, collider #2</Select.Option>
                                                        <Select.Option value="9800">9800 - MS Office &lt;= 2003 $3/$4, SHA1 + RC4</Select.Option>
                                                        <Select.Option value="9810">9810 - MS Office &lt;= 2003 $3, SHA1 + RC4, collider #1</Select.Option>
                                                        <Select.Option value="9820">9820 - MS Office &lt;= 2003 $3, SHA1 + RC4, collider #2</Select.Option>
                                                        <Select.Option value="9900">9900 - Radmin2</Select.Option>
                                                        <Select.Option value="10000">10000 - Django (PBKDF2-SHA256)</Select.Option>
                                                        <Select.Option value="10100">10100 - SipHash</Select.Option>
                                                        <Select.Option value="10200">10200 - CRAM-MD5</Select.Option>
                                                        <Select.Option value="10300">10300 - SAP CODVN H (PWDSALTEDHASH) iSSHA-1</Select.Option>
                                                        <Select.Option value="10400">10400 - PDF 1.1 - 1.3 (Acrobat 2 - 4)</Select.Option>
                                                        <Select.Option value="10410">10410 - PDF 1.1 - 1.3 (Acrobat 2 - 4), collider #1</Select.Option>
                                                        <Select.Option value="10420">10420 - PDF 1.1 - 1.3 (Acrobat 2 - 4), collider #2</Select.Option>
                                                        <Select.Option value="10500">10500 - PDF 1.4 - 1.6 (Acrobat 5 - 8)</Select.Option>
                                                        <Select.Option value="10600">10600 - PDF 1.7 Level 3 (Acrobat 9)</Select.Option>
                                                        <Select.Option value="10700">10700 - PDF 1.7 Level 8 (Acrobat 10 - 11)</Select.Option>
                                                        <Select.Option value="10800">10800 - SHA2-384</Select.Option>
                                                        <Select.Option value="10810">10810 - sha384($pass.$salt)</Select.Option>
                                                        <Select.Option value="10820">10820 - sha384($salt.$pass)</Select.Option>
                                                        <Select.Option value="10830">10830 - sha384(utf16le($pass).$salt)</Select.Option>
                                                        <Select.Option value="10840">10840 - sha384($salt.utf16le($pass))</Select.Option>
                                                        <Select.Option value="10870">10870 - sha384(utf16le($pass))</Select.Option>
                                                        <Select.Option value="10900">10900 - PBKDF2-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="10901">10901 - RedHat 389-DS LDAP (PBKDF2-HMAC-SHA256)</Select.Option>
                                                        <Select.Option value="11000">11000 - PrestaShop</Select.Option>
                                                        <Select.Option value="11100">11100 - PostgreSQL CRAM (MD5)</Select.Option>
                                                        <Select.Option value="11200">11200 - MySQL CRAM (SHA1)</Select.Option>
                                                        <Select.Option value="11300">11300 - Bitcoin/Litecoin wallet.dat</Select.Option>
                                                        <Select.Option value="11400">11400 - SIP digest authentication (MD5)</Select.Option>
                                                        <Select.Option value="11500">11500 - CRC32</Select.Option>
                                                        <Select.Option value="11600">11600 - 7-Zip</Select.Option>
                                                        <Select.Option value="11700">11700 - GOST R 34.11-2012 (Streebog) 256-bit, big-endian</Select.Option>
                                                        <Select.Option value="11750">11750 - HMAC-Streebog-256 (key = $pass), big-endian</Select.Option>
                                                        <Select.Option value="11760">11760 - HMAC-Streebog-256 (key = $salt), big-endian</Select.Option>
                                                        <Select.Option value="11800">11800 - GOST R 34.11-2012 (Streebog) 512-bit, big-endian</Select.Option>
                                                        <Select.Option value="11850">11850 - HMAC-Streebog-512 (key = $pass), big-endian</Select.Option>
                                                        <Select.Option value="11860">11860 - HMAC-Streebog-512 (key = $salt), big-endian</Select.Option>
                                                        <Select.Option value="11900">11900 - PBKDF2-HMAC-MD5</Select.Option>
                                                        <Select.Option value="12001">12001 - Atlassian (PBKDF2-HMAC-SHA1)</Select.Option>
                                                        <Select.Option value="12100">12100 - PBKDF2-HMAC-SHA512</Select.Option>
                                                        <Select.Option value="12200">12200 - eCryptfs</Select.Option>
                                                        <Select.Option value="12300">12300 - Oracle T: Type (Oracle 12+)</Select.Option>
                                                        <Select.Option value="12400">12400 - BSDi Crypt, Extended DES</Select.Option>
                                                        <Select.Option value="12500">12500 - RAR3-hp</Select.Option>
                                                        <Select.Option value="12600">12600 - ColdFusion 10+</Select.Option>
                                                        <Select.Option value="12700">12700 - Blockchain, My Wallet</Select.Option>
                                                        <Select.Option value="12800">12800 - MS-AzureSync PBKDF2-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="12900">12900 - Android FDE (Samsung DEK)</Select.Option>
                                                        <Select.Option value="13000">13000 - RAR5</Select.Option>
                                                        <Select.Option value="13100">13100 - Kerberos 5, etype 23, TGS-REP</Select.Option>
                                                        <Select.Option value="13200">13200 - AxCrypt 1</Select.Option>
                                                        <Select.Option value="13300">13300 - AxCrypt 1 in-memory SHA1</Select.Option>
                                                        <Select.Option value="13400">13400 - KeePass 1 (AES/Twofish) and KeePass 2 (AES)</Select.Option>
                                                        <Select.Option value="13500">13500 - PeopleSoft PS_TOKEN</Select.Option>
                                                        <Select.Option value="13600">13600 - WinZip</Select.Option>
                                                        <Select.Option value="13800">13800 - Windows Phone 8+ PIN/password</Select.Option>
                                                        <Select.Option value="13900">13900 - OpenCart</Select.Option>
                                                        <Select.Option value="14000">14000 - DES (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="14100">14100 - 3DES (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="14400">14400 - sha1(CX)</Select.Option>
                                                        <Select.Option value="14500">14500 - Linux Kernel Crypto API (2.4)</Select.Option>
                                                        <Select.Option value="14600">14600 - LUKS v1 (legacy)</Select.Option>
                                                        <Select.Option value="14700">14700 - iTunes backup &lt; 10.0</Select.Option>
                                                        <Select.Option value="14800">14800 - iTunes backup >= 10.0</Select.Option>
                                                        <Select.Option value="14900">14900 - Skip32 (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="15000">15000 - FileZilla Server >= 0.9.55</Select.Option>
                                                        <Select.Option value="15100">15100 - Juniper/NetBSD sha1crypt</Select.Option>
                                                        <Select.Option value="15200">15200 - Blockchain, My Wallet, V2</Select.Option>
                                                        <Select.Option value="15300">15300 - DPAPI masterkey file v1 (context 1 and 2)</Select.Option>
                                                        <Select.Option value="15310">15310 - DPAPI masterkey file v1 (context 3)</Select.Option>
                                                        <Select.Option value="15400">15400 - ChaCha20</Select.Option>
                                                        <Select.Option value="15500">15500 - JKS Java Key Store Private Keys (SHA1)</Select.Option>
                                                        <Select.Option value="15600">15600 - Ethereum Wallet, PBKDF2-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="15700">15700 - Ethereum Wallet, SCRYPT</Select.Option>
                                                        <Select.Option value="15900">15900 - DPAPI masterkey file v2 (context 1 and 2)</Select.Option>
                                                        <Select.Option value="15910">15910 - DPAPI masterkey file v2 (context 3)</Select.Option>
                                                        <Select.Option value="16000">16000 - Tripcode</Select.Option>
                                                        <Select.Option value="16100">16100 - TACACS+</Select.Option>
                                                        <Select.Option value="16200">16200 - Apple Secure Notes</Select.Option>
                                                        <Select.Option value="16300">16300 - Ethereum Pre-Sale Wallet, PBKDF2-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="16400">16400 - CRAM-MD5 Dovecot</Select.Option>
                                                        <Select.Option value="16500">16500 - JWT (JSON Web Token)</Select.Option>
                                                        <Select.Option value="16600">16600 - Electrum Wallet (Salt-Type 1-3)</Select.Option>
                                                        <Select.Option value="16700">16700 - FileVault 2</Select.Option>
                                                        <Select.Option value="16800">16800 - WPA-PMKID-PBKDF2</Select.Option>
                                                        <Select.Option value="16801">16801 - WPA-PMKID-PMK</Select.Option>
                                                        <Select.Option value="16900">16900 - Ansible Vault</Select.Option>
                                                        <Select.Option value="17010">17010 - GPG (AES-128/AES-256 (SHA-1($pass)))</Select.Option>
                                                        <Select.Option value="17020">17020 - GPG (AES-128/AES-256 (SHA-512($pass)))</Select.Option>
                                                        <Select.Option value="17030">17030 - GPG (AES-128/AES-256 (SHA-256($pass)))</Select.Option>
                                                        <Select.Option value="17200">17200 - PKZIP (Compressed)</Select.Option>
                                                        <Select.Option value="17210">17210 - PKZIP (Uncompressed)</Select.Option>
                                                        <Select.Option value="17220">17220 - PKZIP (Compressed Multi-File)</Select.Option>
                                                        <Select.Option value="17225">17225 - PKZIP (Mixed Multi-File)</Select.Option>
                                                        <Select.Option value="17230">17230 - PKZIP (Mixed Multi-File Checksum-Only)</Select.Option>
                                                        <Select.Option value="17300">17300 - SHA3-224</Select.Option>
                                                        <Select.Option value="17400">17400 - SHA3-256</Select.Option>
                                                        <Select.Option value="17500">17500 - SHA3-384</Select.Option>
                                                        <Select.Option value="17600">17600 - SHA3-512</Select.Option>
                                                        <Select.Option value="17700">17700 - Keccak-224</Select.Option>
                                                        <Select.Option value="17800">17800 - Keccak-256</Select.Option>
                                                        <Select.Option value="17900">17900 - Keccak-384</Select.Option>
                                                        <Select.Option value="18000">18000 - Keccak-512</Select.Option>
                                                        <Select.Option value="18100">18100 - TOTP (HMAC-SHA1)</Select.Option>
                                                        <Select.Option value="18200">18200 - Kerberos 5, etype 23, AS-REP</Select.Option>
                                                        <Select.Option value="18300">18300 - Apple File System (APFS)</Select.Option>
                                                        <Select.Option value="18400">18400 - Open Document Format (ODF) 1.2 (SHA-256, AES)</Select.Option>
                                                        <Select.Option value="18500">18500 - sha1(md5(md5($pass)))</Select.Option>
                                                        <Select.Option value="18501">18501 - sha1(md5(sha1($pass)))</Select.Option>
                                                        <Select.Option value="18600">18600 - Open Document Format (ODF) 1.1 (SHA-1, Blowfish)</Select.Option>
                                                        <Select.Option value="18700">18700 - Java Object hashCode()</Select.Option>
                                                        <Select.Option value="18800">18800 - Blockchain, My Wallet, Second Password (SHA256)</Select.Option>
                                                        <Select.Option value="18900">18900 - Android Backup</Select.Option>
                                                        <Select.Option value="19000">19000 - QNX /etc/shadow (MD5)</Select.Option>
                                                        <Select.Option value="19100">19100 - QNX /etc/shadow (SHA256)</Select.Option>
                                                        <Select.Option value="19200">19200 - QNX /etc/shadow (SHA512)</Select.Option>
                                                        <Select.Option value="19300">19300 - sha1($salt1.$pass.$salt2)</Select.Option>
                                                        <Select.Option value="19500">19500 - Ruby on Rails Restful-Authentication</Select.Option>
                                                        <Select.Option value="19600">19600 - Kerberos 5, etype 17, TGS-REP</Select.Option>
                                                        <Select.Option value="19700">19700 - Kerberos 5, etype 18, TGS-REP</Select.Option>
                                                        <Select.Option value="19800">19800 - Kerberos 5, etype 17, Pre-Auth</Select.Option>
                                                        <Select.Option value="19900">19900 - Kerberos 5, etype 18, Pre-Auth</Select.Option>
                                                        <Select.Option value="20011">20011 - DiskCryptor SHA512 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="20012">20012 - DiskCryptor SHA512 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="20013">20013 - DiskCryptor SHA512 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="20200">20200 - Python passlib pbkdf2-sha512</Select.Option>
                                                        <Select.Option value="20300">20300 - Python passlib pbkdf2-sha256</Select.Option>
                                                        <Select.Option value="20400">20400 - Python passlib pbkdf2-sha1</Select.Option>
                                                        <Select.Option value="20500">20500 - PKZIP Master Key</Select.Option>
                                                        <Select.Option value="20510">20510 - PKZIP Master Key (6 byte optimization)</Select.Option>
                                                        <Select.Option value="20600">20600 - Oracle Transportation Management (SHA256)</Select.Option>
                                                        <Select.Option value="20710">20710 - sha256(sha256($pass).$salt)</Select.Option>
                                                        <Select.Option value="20711">20711 - AuthMe sha256</Select.Option>
                                                        <Select.Option value="20720">20720 - sha256($salt.sha256($pass))</Select.Option>
                                                        <Select.Option value="20800">20800 - sha256(md5($pass))</Select.Option>
                                                        <Select.Option value="20900">20900 - md5(sha1($pass).md5($pass).sha1($pass))</Select.Option>
                                                        <Select.Option value="21000">21000 - BitShares v0.x - sha512(sha512_bin(pass))</Select.Option>
                                                        <Select.Option value="21100">21100 - sha1(md5($pass.$salt))</Select.Option>
                                                        <Select.Option value="21200">21200 - md5(sha1($salt).md5($pass))</Select.Option>
                                                        <Select.Option value="21300">21300 - md5($salt.sha1($salt.$pass))</Select.Option>
                                                        <Select.Option value="21400">21400 - sha256(sha256_bin($pass))</Select.Option>
                                                        <Select.Option value="21420">21420 - sha256($salt.sha256_bin($pass))</Select.Option>
                                                        <Select.Option value="21500">21500 - SolarWinds Orion</Select.Option>
                                                        <Select.Option value="21501">21501 - SolarWinds Orion v2</Select.Option>
                                                        <Select.Option value="21600">21600 - Web2py pbkdf2-sha512</Select.Option>
                                                        <Select.Option value="21700">21700 - Electrum Wallet (Salt-Type 4)</Select.Option>
                                                        <Select.Option value="21800">21800 - Electrum Wallet (Salt-Type 5)</Select.Option>
                                                        <Select.Option value="22000">22000 - WPA-PBKDF2-PMKID+EAPOL</Select.Option>
                                                        <Select.Option value="22001">22001 - WPA-PMK-PMKID+EAPOL</Select.Option>
                                                        <Select.Option value="22100">22100 - BitLocker</Select.Option>
                                                        <Select.Option value="22200">22200 - Citrix NetScaler (SHA512)</Select.Option>
                                                        <Select.Option value="22300">22300 - sha256($salt.$pass.$salt)</Select.Option>
                                                        <Select.Option value="22301">22301 - Telegram Mobile App Passcode (SHA256)</Select.Option>
                                                        <Select.Option value="22400">22400 - AES Crypt (SHA256)</Select.Option>
                                                        <Select.Option value="22500">22500 - MultiBit Classic .key (MD5)</Select.Option>
                                                        <Select.Option value="22600">22600 - Telegram Desktop &lt; v2.1.14 (PBKDF2-HMAC-SHA1)</Select.Option>
                                                        <Select.Option value="22700">22700 - MultiBit HD (scrypt)</Select.Option>
                                                        <Select.Option value="22911">22911 - RSA/DSA/EC/OpenSSH Private Keys ($0$)</Select.Option>
                                                        <Select.Option value="22921">22921 - RSA/DSA/EC/OpenSSH Private Keys ($6$)</Select.Option>
                                                        <Select.Option value="22931">22931 - RSA/DSA/EC/OpenSSH Private Keys ($1, $3$)</Select.Option>
                                                        <Select.Option value="22941">22941 - RSA/DSA/EC/OpenSSH Private Keys ($4$)</Select.Option>
                                                        <Select.Option value="22951">22951 - RSA/DSA/EC/OpenSSH Private Keys ($5$)</Select.Option>
                                                        <Select.Option value="23001">23001 - SecureZIP AES-128</Select.Option>
                                                        <Select.Option value="23002">23002 - SecureZIP AES-192</Select.Option>
                                                        <Select.Option value="23003">23003 - SecureZIP AES-256</Select.Option>
                                                        <Select.Option value="23100">23100 - Apple Keychain</Select.Option>
                                                        <Select.Option value="23200">23200 - XMPP SCRAM PBKDF2-SHA1</Select.Option>
                                                        <Select.Option value="23300">23300 - Apple iWork</Select.Option>
                                                        <Select.Option value="23400">23400 - Bitwarden</Select.Option>
                                                        <Select.Option value="23500">23500 - AxCrypt 2 AES-128</Select.Option>
                                                        <Select.Option value="23600">23600 - AxCrypt 2 AES-256</Select.Option>
                                                        <Select.Option value="23700">23700 - RAR3-p (Uncompressed)</Select.Option>
                                                        <Select.Option value="23800">23800 - RAR3-p (Compressed)</Select.Option>
                                                        <Select.Option value="23900">23900 - BestCrypt v3 Volume Encryption</Select.Option>
                                                        <Select.Option value="24100">24100 - MongoDB ServerKey SCRAM-SHA-1</Select.Option>
                                                        <Select.Option value="24200">24200 - MongoDB ServerKey SCRAM-SHA-256</Select.Option>
                                                        <Select.Option value="24300">24300 - sha1($salt.sha1($pass.$salt))</Select.Option>
                                                        <Select.Option value="24410">24410 - PKCS#8 Private Keys (PBKDF2-HMAC-SHA1 + 3DES/AES)</Select.Option>
                                                        <Select.Option value="24420">24420 - PKCS#8 Private Keys (PBKDF2-HMAC-SHA256 + 3DES/AES)</Select.Option>
                                                        <Select.Option value="24500">24500 - Telegram Desktop >= v2.1.14 (PBKDF2-HMAC-SHA512)</Select.Option>
                                                        <Select.Option value="24600">24600 - SQLCipher</Select.Option>
                                                        <Select.Option value="24700">24700 - Stuffit5</Select.Option>
                                                        <Select.Option value="24800">24800 - Umbraco HMAC-SHA1</Select.Option>
                                                        <Select.Option value="24900">24900 - Dahua Authentication MD5</Select.Option>
                                                        <Select.Option value="25000">25000 - SNMPv3 HMAC-MD5-96/HMAC-SHA1-96</Select.Option>
                                                        <Select.Option value="25100">25100 - SNMPv3 HMAC-MD5-96</Select.Option>
                                                        <Select.Option value="25200">25200 - SNMPv3 HMAC-SHA1-96</Select.Option>
                                                        <Select.Option value="25300">25300 - MS Office 2016 - SheetProtection</Select.Option>
                                                        <Select.Option value="25400">25400 - PDF 1.4 - 1.6 (Acrobat 5 - 8) - user and owner pass</Select.Option>
                                                        <Select.Option value="25500">25500 - Stargazer Stellar Wallet XLM</Select.Option>
                                                        <Select.Option value="25600">25600 - bcrypt(md5($pass)) / bcryptmd5</Select.Option>
                                                        <Select.Option value="25700">25700 - MurmurHash</Select.Option>
                                                        <Select.Option value="25800">25800 - bcrypt(sha1($pass)) / bcryptsha1</Select.Option>
                                                        <Select.Option value="25900">25900 - KNX IP Secure - Device Authentication Code</Select.Option>
                                                        <Select.Option value="26000">26000 - Mozilla key3.db</Select.Option>
                                                        <Select.Option value="26100">26100 - Mozilla key4.db</Select.Option>
                                                        <Select.Option value="26200">26200 - OpenEdge Progress Encode</Select.Option>
                                                        <Select.Option value="26300">26300 - FortiGate256 (FortiOS256)</Select.Option>
                                                        <Select.Option value="26401">26401 - AES-128-ECB NOKDF (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="26402">26402 - AES-192-ECB NOKDF (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="26403">26403 - AES-256-ECB NOKDF (PT = $salt, key = $pass)</Select.Option>
                                                        <Select.Option value="26500">26500 - iPhone passcode (UID key + System Keybag)</Select.Option>
                                                        <Select.Option value="26600">26600 - MetaMask Wallet (needs all data, checks AES-GCM tag)</Select.Option>
                                                        <Select.Option value="26610">26610 - MetaMask Wallet (short hash, plaintext check)</Select.Option>
                                                        <Select.Option value="26700">26700 - SNMPv3 HMAC-SHA224-128</Select.Option>
                                                        <Select.Option value="26800">26800 - SNMPv3 HMAC-SHA256-192</Select.Option>
                                                        <Select.Option value="26900">26900 - SNMPv3 HMAC-SHA384-256</Select.Option>
                                                        <Select.Option value="27000">27000 - NetNTLMv1 / NetNTLMv1+ESS (NT)</Select.Option>
                                                        <Select.Option value="27100">27100 - NetNTLMv2 (NT)</Select.Option>
                                                        <Select.Option value="27200">27200 - Ruby on Rails Restful Auth (one round, no sitekey)</Select.Option>
                                                        <Select.Option value="27300">27300 - SNMPv3 HMAC-SHA512-384</Select.Option>
                                                        <Select.Option value="27400">27400 - VMware VMX (PBKDF2-HMAC-SHA1 + AES-256-CBC)</Select.Option>
                                                        <Select.Option value="27500">27500 - VirtualBox (PBKDF2-HMAC-SHA256 & AES-128-XTS)</Select.Option>
                                                        <Select.Option value="27600">27600 - VirtualBox (PBKDF2-HMAC-SHA256 & AES-256-XTS)</Select.Option>
                                                        <Select.Option value="27700">27700 - MultiBit Classic .wallet (scrypt)</Select.Option>
                                                        <Select.Option value="27800">27800 - MurmurHash3</Select.Option>
                                                        <Select.Option value="27900">27900 - CRC32C</Select.Option>
                                                        <Select.Option value="28000">28000 - CRC64Jones</Select.Option>
                                                        <Select.Option value="28100">28100 - Windows Hello PIN/Password</Select.Option>
                                                        <Select.Option value="28200">28200 - Exodus Desktop Wallet (scrypt)</Select.Option>
                                                        <Select.Option value="28300">28300 - Teamspeak 3 (channel hash)</Select.Option>
                                                        <Select.Option value="28400">28400 - bcrypt(sha512($pass)) / bcryptsha512</Select.Option>
                                                        <Select.Option value="28501">28501 - Bitcoin WIF private key (P2PKH), compressed</Select.Option>
                                                        <Select.Option value="28502">28502 - Bitcoin WIF private key (P2PKH), uncompressed</Select.Option>
                                                        <Select.Option value="28503">28503 - Bitcoin WIF private key (P2WPKH, Bech32), compressed</Select.Option>
                                                        <Select.Option value="28504">28504 - Bitcoin WIF private key (P2WPKH, Bech32), uncompressed</Select.Option>
                                                        <Select.Option value="28505">28505 - Bitcoin WIF private key (P2SH(P2WPKH)), compressed</Select.Option>
                                                        <Select.Option value="28506">28506 - Bitcoin WIF private key (P2SH(P2WPKH)), uncompressed</Select.Option>
                                                        <Select.Option value="28600">28600 - PostgreSQL SCRAM-SHA-256</Select.Option>
                                                        <Select.Option value="28700">28700 - Amazon AWS4-HMAC-SHA256</Select.Option>
                                                        <Select.Option value="28800">28800 - Kerberos 5, etype 17, DB</Select.Option>
                                                        <Select.Option value="28900">28900 - Kerberos 5, etype 18, DB</Select.Option>
                                                        <Select.Option value="29000">29000 - sha1($salt.sha1(utf16le($username).':'.utf16le($pass)))</Select.Option>
                                                        <Select.Option value="29100">29100 - Flask Session Cookie ($salt.$salt.$pass)</Select.Option>
                                                        <Select.Option value="29200">29200 - Radmin3</Select.Option>
                                                        <Select.Option value="29311">29311 - TrueCrypt RIPEMD160 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29312">29312 - TrueCrypt RIPEMD160 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29313">29313 - TrueCrypt RIPEMD160 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29321">29321 - TrueCrypt SHA512 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29322">29322 - TrueCrypt SHA512 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29323">29323 - TrueCrypt SHA512 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29331">29331 - TrueCrypt Whirlpool + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29332">29332 - TrueCrypt Whirlpool + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29333">29333 - TrueCrypt Whirlpool + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29341">29341 - TrueCrypt RIPEMD160 + XTS 512 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29342">29342 - TrueCrypt RIPEMD160 + XTS 1024 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29343">29343 - TrueCrypt RIPEMD160 + XTS 1536 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29411">29411 - VeraCrypt RIPEMD160 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29412">29412 - VeraCrypt RIPEMD160 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29413">29413 - VeraCrypt RIPEMD160 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29421">29421 - VeraCrypt SHA512 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29422">29422 - VeraCrypt SHA512 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29423">29423 - VeraCrypt SHA512 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29431">29431 - VeraCrypt Whirlpool + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29432">29432 - VeraCrypt Whirlpool + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29433">29433 - VeraCrypt Whirlpool + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29441">29441 - VeraCrypt RIPEMD160 + XTS 512 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29442">29442 - VeraCrypt RIPEMD160 + XTS 1024 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29443">29443 - VeraCrypt RIPEMD160 + XTS 1536 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29451">29451 - VeraCrypt SHA256 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29452">29452 - VeraCrypt SHA256 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29453">29453 - VeraCrypt SHA256 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29461">29461 - VeraCrypt SHA256 + XTS 512 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29462">29462 - VeraCrypt SHA256 + XTS 1024 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29463">29463 - VeraCrypt SHA256 + XTS 1536 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29471">29471 - VeraCrypt Streebog-512 + XTS 512 bit</Select.Option>
                                                        <Select.Option value="29472">29472 - VeraCrypt Streebog-512 + XTS 1024 bit</Select.Option>
                                                        <Select.Option value="29473">29473 - VeraCrypt Streebog-512 + XTS 1536 bit</Select.Option>
                                                        <Select.Option value="29481">29481 - VeraCrypt Streebog-512 + XTS 512 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29482">29482 - VeraCrypt Streebog-512 + XTS 1024 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29483">29483 - VeraCrypt Streebog-512 + XTS 1536 bit + boot-mode</Select.Option>
                                                        <Select.Option value="29511">29511 - LUKS v1 SHA-1 + AES</Select.Option>
                                                        <Select.Option value="29512">29512 - LUKS v1 SHA-1 + Serpent</Select.Option>
                                                        <Select.Option value="29513">29513 - LUKS v1 SHA-1 + Twofish</Select.Option>
                                                        <Select.Option value="29521">29521 - LUKS v1 SHA-256 + AES</Select.Option>
                                                        <Select.Option value="29522">29522 - LUKS v1 SHA-256 + Serpent</Select.Option>
                                                        <Select.Option value="29523">29523 - LUKS v1 SHA-256 + Twofish</Select.Option>
                                                        <Select.Option value="29531">29531 - LUKS v1 SHA-512 + AES</Select.Option>
                                                        <Select.Option value="29532">29532 - LUKS v1 SHA-512 + Serpent</Select.Option>
                                                        <Select.Option value="29533">29533 - LUKS v1 SHA-512 + Twofish</Select.Option>
                                                        <Select.Option value="29541">29541 - LUKS v1 RIPEMD-160 + AES</Select.Option>
                                                        <Select.Option value="29542">29542 - LUKS v1 RIPEMD-160 + Serpent</Select.Option>
                                                        <Select.Option value="29543">29543 - LUKS v1 RIPEMD-160 + Twofish</Select.Option>
                                                        <Select.Option value="29600">29600 - Terra Station Wallet (AES256-CBC(PBKDF2($pass)))</Select.Option>
                                                        <Select.Option value="29700">29700 - KeePass 1 (AES/Twofish) and KeePass 2 (AES) - keyfile only mode</Select.Option>
                                                        <Select.Option value="29800">29800 - Bisq .wallet (scrypt)</Select.Option>
                                                        <Select.Option value="29910">29910 - ENCsecurity Datavault (PBKDF2/no keychain)</Select.Option>
                                                        <Select.Option value="29920">29920 - ENCsecurity Datavault (PBKDF2/keychain)</Select.Option>
                                                        <Select.Option value="29930">29930 - ENCsecurity Datavault (MD5/no keychain)</Select.Option>
                                                        <Select.Option value="29940">29940 - ENCsecurity Datavault (MD5/keychain)</Select.Option>
                                                        <Select.Option value="30000">30000 - Python Werkzeug MD5 (HMAC-MD5 (key = $salt))</Select.Option>
                                                        <Select.Option value="30120">30120 - Python Werkzeug SHA256 (HMAC-SHA256 (key = $salt))</Select.Option>
                                                        <Select.Option value="30420">30420 - DANE RFC7929/RFC8162 SHA2-256</Select.Option>
                                                        <Select.Option value="30500">30500 - md5(md5($salt).md5(md5($pass)))</Select.Option>
                                                        <Select.Option value="30600">30600 - bcrypt(sha256($pass)) / bcryptsha256</Select.Option>
                                                        <Select.Option value="30700">30700 - Anope IRC Services (enc_sha256)</Select.Option>
                                                        <Select.Option value="30901">30901 - Bitcoin raw private key (P2PKH), compressed</Select.Option>
                                                        <Select.Option value="30902">30902 - Bitcoin raw private key (P2PKH), uncompressed</Select.Option>
                                                        <Select.Option value="30903">30903 - Bitcoin raw private key (P2WPKH, Bech32), compressed</Select.Option>
                                                        <Select.Option value="30904">30904 - Bitcoin raw private key (P2WPKH, Bech32), uncompressed</Select.Option>
                                                        <Select.Option value="30905">30905 - Bitcoin raw private key (P2SH(P2WPKH)), compressed</Select.Option>
                                                        <Select.Option value="30906">30906 - Bitcoin raw private key (P2SH(P2WPKH)), uncompressed</Select.Option>
                                                        <Select.Option value="31000">31000 - BLAKE2s-256</Select.Option>
                                                        <Select.Option value="31100">31100 - SM3</Select.Option>
                                                        <Select.Option value="31200">31200 - Veeam VBK</Select.Option>
                                                        <Select.Option value="31300">31300 - MS SNTP</Select.Option>
                                                        <Select.Option value="31400">31400 - SecureCRT MasterPassphrase v2</Select.Option>
                                                        <Select.Option value="31700">31700 - md5(md5(md5($pass).$salt1).$salt2)</Select.Option>
                                                        <Select.Option value="31800">31800 - 1Password, mobilekeychain (1Password 8)</Select.Option>
                                                        <Select.Option value="31900">31900 - MetaMask Mobile Wallet</Select.Option>
                                                    </Select>
                                                    <Upload
                                                        {...(index === 0) ? props : ""}
                                                        {...(index === 1) ? props2 : ""}
                                                        {...(index === 2) ? props3 : ""}
                                                        {...(index === 3) ? props4 : ""}
                                                        {...(index === 4) ? props5 : ""}
                                                        style={{ width: '40%' }}>
                                                        <Button icon={<UploadOutlined />}>Attach file</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    if(fields.length < 5) {
                                                        add();
                                                    } else {
                                                        message.error("Cannot add more than 5 algorithms to one breach. If more are required, contact support.")
                                                    }
                                                }}
                                                style={{ width: '60%' }}
                                                icon={<PlusOutlined />}
                                            >
                                                Add algorithm
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>

                        <Form.Item
                            label="Notes"
                            tooltip=""
                            name={"notes"}
                        >
                            <TextArea rows={4} name={'notes'} placeholder="Additional notes relevant to the breach. Method of being breached, who breached it, disclosed information, sitekeys, any information is welcome." />
                        </Form.Item>

                        It is possible to submit your application without adding algorithms. <br/>
                        This does however mean that the HashMob staff will be responsible for:<br/>
                        - parsing the database<br/>
                        - obtaining the relevant information<br/>
                        - extracting the hashes<br/>
                        - identifying the hash algorithm(s)<br/>
                        As a result it might take longer to process the application request.<br/>
                        <br/>
                        <Form.Item>
                            <Button type="primary" disabled={uploading} onClick={doSubmit}>Submit</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Row>
        </Content>
    );
};

export default SubmitLeak;