import React, {useState} from 'react';
import {Layout, Row, Card, Col, Form, Input, Button, notification, message} from "antd";
import axios from "axios";
import { Navigate } from 'react-router-dom';
import {useRecoilState} from "recoil";
import {userdataAtom} from "../../state_atoms";

const { Content } = Layout;
const Login: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [userData, setUserData] = useRecoilState(userdataAtom);

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const update_user_session = async () => {
        await axios.get('https://' + window.location.hostname + '/api/user', {withCredentials: true})
            .then(response => {
                if (response.data.hasOwnProperty('username')) {
                    if(userData !== response.data) {
                        setUserData(response.data);
                        setLoading(false);
                    }
                } else {
                    setUserData(false);
                    setLoading(false);
                }
            }).catch(error => {
                console.log(error.data);
                setLoading(false);
            })
    }

    const onFinish = (values: any) => {
        setLoading(true);
        axios.post('https://' + window.location.hostname + '/api/authenticate', values, {withCredentials: true})
            .then(response => {
                update_user_session().then(r => {
                    message.success('Logged In! Welcome back.', 5);
                    setLoading(false);
                }).finally(() => {
                    setRedirect(true);
                });
            }).catch(function(error) {
            if(error.response) {
                if(error.response.data.hasOwnProperty("error_message")) {
                    Object.values(error.response.data.error_message).map(function (item) {
                        notification.error({
                            message: 'Login Failed',
                            description: item,
                            duration: 5
                        });
                    });
                } else {
                    notification.error({
                        message: 'Login Failed',
                        description: error.response.data.message,
                        duration: 5
                    });
                    // message.error("Upload failed. " + error.response.data.message, 30);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    if(userData) {
        return <Navigate to="/admin"/>
    }
    return (
        <Content
            style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
            }}
        >
            <Row gutter={16}>
                <Col span={12} offset={6}>
                    <Card title={"Login"}>
                        Welcome to the HashMob leak submission management interface. If you are not a moderator or appointed individual, you will not be able to log in with your HashMob credentials.<br/>
                        <br/>
                        <Form
                            name="basic"
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    { required: true, message: 'Please input your username!' },
                                    { min: 2, message: 'Password must be minimum 2 characters.' }
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    { required: true, message: 'Please input your password!' },
                                    { min: 8, message: 'Password must be minimum 8 characters.' },
                                    { max: 72, message: 'Password must be less than 72 characters.' }
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button type="primary" htmlType="submit" style={{width: "100%"}}>
                                    Log In
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};

export default Login;